import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import { InfoSelectionPage } from "./InfoSelectionPage";
import { InstitutionInfoPage } from "./InstituteInfoPage";
import { SchoolSelectionPage } from "./SchoolSelectionPage";

export const InfohubRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}`} component={InfoSelectionPage} />
      <Route exact path={`${path}/schools`} component={SchoolSelectionPage} />
      <Route
        exact
        path={`${path}/schools/:institutionId`}
        component={InstitutionInfoPage}
      />
      {/* Handle invalid paths for this route below: */}
      <Route>Sorry, we can't seem to find that page! [404]</Route>
    </Switch>
  );
};
