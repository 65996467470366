import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../config/store";
import { Topic } from "../Topic";
import { AudienceOption } from "./PostOptionSelector";

export interface AudienceOptions {
  [AudienceOption.HighSchoolStudents]: boolean;
  [AudienceOption.CurrentStudents]: boolean;
  [AudienceOption.Alumni]: boolean;
  [AudienceOption.EndorsedMembers]: boolean;
  [AudienceOption.PeopleFromInstitutionsInTopics]: boolean;
}

interface NewPostPageState {
  title: string;
  text: string;
  subscribedToByAuthor: boolean;
  selectedTopics: Topic[];
  audienceOptions: AudienceOptions;
}

function getInitialState(): NewPostPageState {
  return {
    title: "",
    text: "",
    subscribedToByAuthor: true,
    selectedTopics: [],
    audienceOptions: {
      [AudienceOption.HighSchoolStudents]: true,
      [AudienceOption.CurrentStudents]: true,
      [AudienceOption.Alumni]: true,
      [AudienceOption.EndorsedMembers]: true,
      [AudienceOption.PeopleFromInstitutionsInTopics]: true,
    },
  };
}

const initialState = getInitialState();

const newPostPageSlice = createSlice({
  name: "newPostPage",
  initialState,
  reducers: {
    resetNewPostState() {
      return getInitialState();
    },
    // One nice feature of using createSlice is that you can treat the state as mutable (i.e., set it and expect it to change)
    setTitle(state, action: PayloadAction<string>) {
      state.title = action.payload;
    },
    setText(state, action: PayloadAction<string>) {
      state.text = action.payload;
    },
    toggleSubscribedToByAuthor(state) {
      state.subscribedToByAuthor = !state.subscribedToByAuthor;
    },
    addSelectedTopic(state, action: PayloadAction<Topic>) {
      state.selectedTopics.push(action.payload);
    },
    removeSelectedTopicAtIndex(state, action: PayloadAction<number>) {
      const indexToRemoveFrom = action.payload;
      state.selectedTopics.splice(indexToRemoveFrom, 1); // Remove 1 element from indexToRemoveFrom
    },
    toggleAudienceOption(state, action: PayloadAction<AudienceOption>) {
      state.audienceOptions[action.payload] =
        !state.audienceOptions[action.payload];
    },
  },
});

// NOTE: These should not be called when the user is not logged in; otherwise the root state is `undefined`
export function getNewPostPageSlice(state: RootState): NewPostPageState {
  return state!.newPost;
}

export const getTitle = createSelector(
  [getNewPostPageSlice],
  (newPostPageSlice) => newPostPageSlice.title
);

export const getText = createSelector(
  [getNewPostPageSlice],
  (newPostPageSlice) => newPostPageSlice.text
);

export const getSubscribedToByAuthor = createSelector(
  [getNewPostPageSlice],
  (newPostPageSlice) => newPostPageSlice.subscribedToByAuthor
);

export const getSelectedTopics = createSelector(
  [getNewPostPageSlice],
  (newPostPageSlice) => newPostPageSlice.selectedTopics
);

export const getAudienceOptions = createSelector(
  [getNewPostPageSlice],
  (newPostPageSlice) => newPostPageSlice.audienceOptions
);

export const {
  resetNewPostState,
  setTitle,
  setText,
  toggleSubscribedToByAuthor,
  addSelectedTopic,
  removeSelectedTopicAtIndex,
  toggleAudienceOption,
} = newPostPageSlice.actions;

export default newPostPageSlice.reducer;
