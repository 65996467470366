import { createMessageReducer, Message } from "pubnub-redux";
import { createSelector } from "reselect";
import { RootState } from "../../config/store";
import { MessageData } from "./MessageData";

export interface CustomMessage extends Message {
  // Unfortunately it's a bit hard to read, but yes, Message has a field called message (originally type object, but overwritten here)
  message: MessageData;
}

/**
 * Create a reducer which holds all known messsage envelope objects in a normalized form
 */
export const MessageStateReducer = createMessageReducer<CustomMessage>();

/**
 * This Slice selector is used internally to access the state of the reducer,
 * primarily as the base selector function for creating other selectors.
 */
const getMessagesSlice = (state: RootState) => state?.messages;

export const getMessagesById = createSelector(
  [getMessagesSlice],
  (messages) => {
    return messages?.byId;
  }
);
