import React, { FC } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../config/redux-hooks";
import { getAudienceOptions, toggleAudienceOption } from "./newPostPageSlice";

const Heading = styled.div`
  margin-bottom: 0.5em;
`;

const PostOptions = styled.div`
  margin-top: 5%;
`;

export enum AudienceOption {
  HighSchoolStudents = "HighSchoolStudents",
  CurrentStudents = "CurrentStudents",
  Alumni = "Alumni",
  EndorsedMembers = "EndorsedMembers",
  PeopleFromInstitutionsInTopics = "PeopleFromInstitutionsInTopics",
}

const audienceOptionsToLabel = {
  [AudienceOption.HighSchoolStudents]: "High School Students",
  [AudienceOption.CurrentStudents]: "Current Students",
  [AudienceOption.Alumni]: "Alumni",
  [AudienceOption.EndorsedMembers]: "Endorsed Members",
  [AudienceOption.PeopleFromInstitutionsInTopics]:
    "People from institutions in the above Topics",
};

export const PostOptionSelector: FC = () => {
  const dispatch = useAppDispatch();
  const audienceOptions = useAppSelector(getAudienceOptions);
  return (
    <PostOptions>
      <Heading>Answers only from:</Heading>
      {(Object.keys(audienceOptions) as AudienceOption[]).map((optionKey) => (
        <Form.Check
          id={optionKey}
          key={optionKey}
          label={audienceOptionsToLabel[optionKey]}
          checked={audienceOptions[optionKey]}
          onChange={() => {
            dispatch(toggleAudienceOption(optionKey));
          }}
        />
      ))}
    </PostOptions>
  );
};
