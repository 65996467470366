import React from "react";
import styled from "styled-components";

const NavBar = styled.nav`
  background-color: #011d84;
  /* background-image: linear-gradient(to right, #011d84, #011d84, #415dc4); */
`;

function Footer() {
  return (
    <footer>
      <NavBar className="navbar navbar-expand-sm navbar-dark justify-content-between">
        <div className="container text-center">
          <p style={{ color: "white" }}>&copy; uConverse 2022.</p>
          <p>
            <a
              style={{ color: "white" }}
              href="https://docs.google.com/forms/d/e/1FAIpQLSeHhO5Wes6mjce2vaDUU7SyLwO3cflxZn0iQRE9Qy1W24dszg/viewform"
            >
              Contact Us
            </a>
          </p>
          <p>
            <a style={{ color: "white" }} href="privacypolicy.html">
              Privacy Policy
            </a>
          </p>
        </div>
      </NavBar>
    </footer>
  );
}

export default Footer;
