import { createStore, applyMiddleware, compose, AnyAction } from "redux";
import ReduxThunk, { ThunkAction } from "redux-thunk";
import { pubnub } from "./pubnubConfig";
import rootReducer from "./rootReducer";

const preloadedState = undefined;

// Setup Redux Dev Tools
export const composeEnhancers =
  (process.env.NODE_ENV === "development" &&
    window &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

/**
 * Create a redux store configured for this application
 */
export const createAppStore = (thunkContext: any) => {
  const storeEnhancer = composeEnhancers(
    applyMiddleware(ReduxThunk.withExtraArgument(thunkContext))
  );

  return createStore(rootReducer, preloadedState, storeEnhancer);
};

export const store = createAppStore({
  pubnub: {
    api: pubnub,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
// Common type for thunks
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;
