import React from "react";
import { Dropdown } from "react-bootstrap";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FullscreenCard } from "./FullscreenCard";
import { getInstitutions } from "./getInstitutions";

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  height: 100%;
`;

const Divider = styled.hr`
  width: 50%;
`;

const ReturnToInfoHubParagraph = styled.p`
  margin-top: 1rem;
`;

const SchoolDropdownToggle = styled(Dropdown.Toggle)`
  border-radius: 100px;
`;

export const SchoolSelectionPage = () => {
  const history = useHistory();
  const institutions = getInstitutions();

  return (
    <FullscreenCard>
      <CardContent>
        <h1>Tell me about a specific school:</h1>
        <Divider />
        <Dropdown>
          <SchoolDropdownToggle>Select a school</SchoolDropdownToggle>
          <Dropdown.Menu>
            {institutions.map(
              ({ institutionId, name, isHighSchool }) =>
                !isHighSchool && (
                  <Dropdown.Item
                    onSelect={() => {
                      history.push("/infohub/schools/" + institutionId);
                    }}
                  >
                    {name}
                  </Dropdown.Item>
                )
            )}
          </Dropdown.Menu>
        </Dropdown>
        <ReturnToInfoHubParagraph>
          Take me <Link to="/infohub">back to the Information Hub</Link>
        </ReturnToInfoHubParagraph>
      </CardContent>
    </FullscreenCard>
  );
};
