import React, { FC } from "react";
import { useSelector } from "react-redux";
import { StaticRouter } from "react-router-dom";
import styled from "styled-components";
import { useAppSelector } from "../../../config/redux-hooks";
import { Comment } from "../reactionTypes";
import { CommentComponent } from "./CommentComponent";
import { getCommentsByParentId } from "./postPageSlice";

const CommentListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

interface CommentListProps {
  id?: string;
  parentId: number;
}

export const CommentList: FC<CommentListProps> = ({ id, parentId }) => {
  const children = useAppSelector(
    (state) => getCommentsByParentId(state)[parentId]
  );
  return (
    <CommentListContainer id={id}>
      {children &&
        children.map((childComment) => (
          <CommentComponent
            key={childComment.reaction.reactionid}
            comment={childComment}
          />
        ))}
    </CommentListContainer>
  );
};
