import { combineReducers } from "redux";
import { pubNubReducers } from "../features/chat/pubnub/pubnubReducers.js";
import { LOGGING_OUT } from "../features/authentication/authenticationModel";
import newPostPageSlice from "../features/forum/newPost/newPostPageSlice";
import postListPageSlice from "../features/forum/postList/postListPageSlice";
import postPageSlice from "../features/forum/post/postPageSlice";

/**
 * Combine all of the reducers in this application
 */
const appReducer = combineReducers({
  ...pubNubReducers,
  postPage: postPageSlice,
  postList: postListPageSlice,
  newPost: newPostPageSlice,
});

export const rootReducer = (state, action) => {
  if (action.type == LOGGING_OUT) {
    // If the user is logging out, reset all the reducers.
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
