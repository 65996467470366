const BackendEndpoint = "https://uconverse-backend.herokuapp.com/";

const universityList = ["Athabasca University",
    "MacEwan University",
    "Mount Royal University",
    "University of Alberta",
    "University of Calgary",
    "University of Lethbridge",
    "Capilano University",
    "Emily Carr University of Art and Design",
    "Kwantlen Polytechnic University",
    "Royal Roads University",
    "Simon Fraser University",
    "Thompson Rivers University",
    "University of British Columbia",
    "University of Victoria",
    "University of the Fraser Valley",
    "University of Northern British Columbia",
    "Vancouver Island University",
    "Brandon University",
    "University College of the North",
    "University of Manitoba",
    "University of Winnipeg",
    "Université de Saint-Boniface",
    "Mount Allison University",
    "St. Thomas University",
    "University of New Brunswick",
    "Université de Moncton",
    "Memorial University of Newfoundland",
    "Acadia University",
    "Cape Breton University",
    "Dalhousie University",
    "University of King's College",
    "Mount Saint Vincent University",
    "NSCAD University",
    "Saint Francis Xavier University",
    "Saint Mary's University",
    "Université Sainte-Anne",
    "Algoma University",
    "Brock University",
    "Carleton University",
    "Dominican University College",
    "Lakehead University",
    "Laurentian University",
    "McMaster University",
    "Nipissing University",
    "OCAD University",
    "Queen's University",
    "Saint Paul University",
    "Royal Military College of Canada",
    "Ryerson University",
    "Trent University",
    "University of Guelph",
    "University of Ontario Institute of Technology",
    "University of Ottawa",
    "University of Toronto",
    "Huron University College",
    "University of Waterloo",
    "University of Western Ontario",
    "University of Windsor",
    "Wilfrid Laurier University",
    "York University",
    "University of Prince Edward Island",
    "Bishop's University",
    "Concordia University",
    "École de technologie supérieure",
    "École nationale d'administration publique",
    "École Polytechnique de Montréal",
    "HEC Montréal",
    "Institut national de la recherche scientifique",
    "McGill University",
    "Université de Montréal",
    "Université de Sherbrooke",
    "Université du Québec en Abitibi-Témiscamingue",
    "Université du Québec en Outaouais",
    "Université du Québec à Chicoutimi",
    "Université du Québec à Montréal",
    "Université du Québec à Rimouski",
    "Université du Québec à Trois-Rivières",
    "Université Laval",
    "First Nations University of Canada",
    "University of Regina",
    "University of Saskatchewan",
    "Harvard University",
    "Stanford University",
    "University of Cambridge",
    "Massachusetts Institute of Technology",
    "Princeton University",
    "University of Oxford",
    "Columbia University",
    "California Institute of Technology",
    "University of Chicago",
    "Yale University",
    "Cornell University",
    "Paris-Saclay University",
    "University of Pennsylvania",
    "Johns Hopkins University",
    "University College London",
    "University of Washington",
    "University of California",
    "Swiss Federal Institute of Technology in Zurich",
    "Washington University in St. Louis",
    "University of Tokyo",
    "Imperial College London",
    "University of Michigan",
    "New York University",
    "Tsinghua University",
    "University of North Carolina at Chapel Hill",
    "University of Copenhagen",
    "University of Wisconsin–Madison",
    "Duke University",
    "University of Melbourne",
    "Northwestern University",
    "Sorbonne University",
    "University of Manchester",
    "Kyoto University",
    "PSL University",
    "University of Edinburgh",
    "University of Minnesota",
    "University of Texas at Austin",
    "Karolinska Institute",
    "Rockefeller University",
    "Peking University",
    "University of Colorado Boulder",
    "King's College London",
    "University of Texas Southwestern Medical Center at Dallas",
    "Ludwig Maximilian University of Munich",
    "Utrecht University",
]

const programList = [
    "< Grade 9",
    "Grade 9",
    "Grade 10",
    "Grade 11",
    "Grade 12",
    "Accounting and Financial Management",
    "Actuarial Science",
    "Anthropology",
    "Applied Mathematics",
    "Architectural Engineering",
    "Architecture",
    "Bachelor of Arts",
    "Bachelor of Science",
    "Biochemistry",
    "Biology",
    "Biomedical Engineering",
    "Biomedical Sciences",
    "Biostatistics",
    "Biotechnology/Chartered Professional Accountancy",
    "Business Administration (Laurier) and Computer Science (Waterloo) Double Degree",
    "Business Administration (Laurier) and Mathematics (Waterloo) Double Degree",
    "Business programs",
    "Chemical Engineering",
    "Chemistry",
    "Civil Engineering",
    "Classical Studies (2 majors available)",
    "Climate and Environmental Change",
    "Co-op programs",
    "Combinatorics and Optimization",
    "Communication Studies",
    "Computational Mathematics",
    "Computer Engineering",
    "Computer Science",
    "Computing and Financial Management",
    "Data Science",
    "Earth Sciences",
    "Economics",
    "Education (teaching)",
    "Electrical Engineering",
    "English (3 majors available)",
    "Environment and Business",
    "Environment, Resources and Sustainability",
    "Environmental Engineering",
    "Environmental programs at a glance",
    "Environmental Science",
    "Fine Arts (2 majors available)",
    "French",
    "Gender and Social Justice",
    "Geography and Aviation",
    "Geography and Environmental Management",
    "Geological Engineering",
    "Geomatics",
    "German",
    "Global Business and Digital Arts",
    "Health Sciences",
    "History",
    "Honours Arts (29 majors available)",
    "Honours Arts and Business (29 majors available)",
    "Honours Science",
    "Information Technology Management",
    "International Development",
    "Kinesiology",
    "Knowledge Integration",
    "Legal Studies",
    "Liberal Studies",
    "Life Physics",
    "Life Sciences (4 majors available)",
    "Management Engineering",
    "Materials and Nanosciences",
    "Mathematical Economics",
    "Mathematical Finance",
    "Mathematical Optimization",
    "Mathematical Physics",
    "Mathematics (14 majors available)",
    "Mathematics/Business Administration",
    "Mathematics/Chartered Professional Accountancy",
    "Mathematics/Financial Analysis and Risk Management",
    "Mathematics / Teaching",
    "Mechanical Engineering",
    "Mechatronics Engineering",
    "Medicinal Chemistry",
    "Medieval Studies",
    "Minors and specializations",
    "Music",
    "Nanotechnology Engineering",
    "Nursing",
    "Optometry",
    "Pathways to medical school and other health professions",
    "Peace and Conflict Studies",
    "Pharmacy",
    "Philosophy",
    "Physical Sciences (8 majors available)",
    "Physics",
    "Physics and Astronomy",
    "Planning",
    "Political Science",
    "Pre - law",
    "Psychology – Bachelor of Arts",
    "Psychology – Bachelor of Science",
    "Public Health",
    "Pure Mathematics",
    "Recreation and Leisure Studies",
    "Recreation and Sport Business",
    "Religious Studies",
    "Science and Aviation",
    "Science and Business",
    "Sexuality, Marriage, and Family Studies",
    "Social Development Studies",
    "Social Work",
    "Sociology",
    "Software Engineering",
    "Spanish",
    "Statistics",
    "Sustainability and Financial Management",
    "Systems Design Engineering",
    "Teaching",
    "Theatre and Performance",
    "Therapeutic Recreation",
]

export { BackendEndpoint, universityList, programList }




