import React, { Component } from "react";
import { connect } from "react-redux";
import { auth } from "../../services/firebase";
import { db } from "../../services/firebase";
import { getCurrentConversationMessages } from "./currentConversationModel.js";
import { joinConversation } from "./pubnub/joinConversationCommand";
import { getUsersListUUIDs } from "./usersListModel";
import { sendTextMessage } from "./pubnub/sendMessage";
import { BackendEndpoint } from "../../constants";

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: auth.currentUser,
      chats: [],
      content: "",
      readError: null,
      writeError: null,
      loadingChats: false,
      type: "student",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.myRef = React.createRef();
  }

  async componentDidMount() {
    this.setState({ readError: null, loadingChats: true });
    const chatArea = this.myRef.current;
    try {
      db.ref("chats").on("value", (snapshot) => {
        let chats = [];
        snapshot.forEach((snap) => {
          chats.push(snap.val());
        });
        chats.sort(function (a, b) {
          return a.timestamp - b.timestamp;
        });
        this.setState({ chats });
        chatArea.scrollBy(0, chatArea.scrollHeight);
        this.setState({ loadingChats: false });
      });
    } catch (error) {
      this.setState({ readError: error.message, loadingChats: false });
    }
    var that = this;
    fetch(`${BackendEndpoint}api/v1/users/mentors`)
      .then((res) => res.json())
      .then(
        (result) => {
          result.data.forEach(function (item, index) {
            
            
            if (item.uid === auth.currentUser.uid) {
              that.state.type = "mentor";
            }
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          that.setState({
            isLoaded: true,
            error,
          });
        }
      );
    
    // if (this.state.type != "mentor") {
    //   this.props.changeConversation(
    //     auth().currentUser.uid,
    //     this.props.location.state.targetUID // TODO: fix error when you go on chat page directly ("_this.props.location.state is undefined")
    //   );
    // }
  }

  handleChange(event) {
    this.setState({
      content: event.target.value,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ writeError: null });
    const chatArea = this.myRef.current;
    try {
      // await db.ref("chats").push({
      //   content: this.state.content,
      //   timestamp: Date.now(),
      //   uid: this.state.user.uid
      // });
      this.props.sendMessage(this.state.content);
      this.setState({ content: "" });
      chatArea.scrollBy(0, chatArea.scrollHeight);
    } catch (error) {
      this.setState({ writeError: error.message });
    }
  }

  formatTime(timestamp) {
    const d = new Date(timestamp);
    const time = `${d.getDate()}/${
      d.getMonth() + 1
    }/${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}`;
    return time;
  }

  renderSelectionTab() {
    
    if (this.state.type === "mentor") {
      return true;
    } else {
      return false;
    }
  }

  render() {
    // Hack for now
    const nameClickHandler = (targetUID) => () => {
      
      this.props.changeConversation(auth.currentUser.uid, targetUID);
      
    };
    
    
    
    return (
      <div>
        <div className="chat-area" ref={this.myRef}>
          {/* loading indicator */}
          {this.state.loadingChats ? (
            <div className="spinner-border text-success" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            ""
          )}
          {/* chat area */}
          {this.props.messages.map((msgData) => {
            return (
              <p
                key={msgData.timestamp}
                className={
                  "chat-bubble " +
                  (this.state.user.uid === msgData.message.senderId
                    ? "current-user"
                    : "")
                }
              >
                {msgData.message.body}
                <br />
                <span className="chat-time float-right">
                  {msgData.timetoken}
                </span>
              </p>
            );
          })}
        </div>
        <form onSubmit={this.handleSubmit} className="mx-3">
          <textarea
            className="form-control"
            name="content"
            onChange={this.handleChange}
            value={this.state.content}
          ></textarea>
          <button type="submit" className="btn btn-submit px-5 mt-4">
            Send
          </button>
        </form>
        <div className="py-5 mx-3">
          Login in as:{" "}
          <strong className="text-info">{this.state.user.email}</strong>
        </div>
        {this.props.usersList.map((userUUID) => {
          return (
            <button className="py-5 mx-3" onClick={nameClickHandler(userUUID)}>
              Talk with {userUUID}
            </button>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    messages: getCurrentConversationMessages(state),
    usersList: getUsersListUUIDs(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeConversation: (currUserUID, targetUID) => {
      const conversationId = [currUserUID, targetUID].sort().join("&");
      dispatch(joinConversation(currUserUID, conversationId));
    },
    sendMessage: (message) => dispatch(sendTextMessage(message)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
