import { getCurrentConversationId } from "../currentConversationModel";
import { sendMessage as sendPubnubMessage } from "pubnub-redux";
import { getLoggedInUserId } from "../../authentication/authenticationModel";
import { AppThunk } from "../../../config/store";
import { MessageData } from "../MessageData";

/**
 * Send a message to the current conversation
 *
 * This command does not handle failure and leaves the error to the caller
 */
export const sendTextMessage = (textMessage: string): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const message: MessageData = {
      type: "text",
      senderId: getLoggedInUserId(state),
      body: textMessage,
    };
    return dispatch(
      sendPubnubMessage({
        channel: getCurrentConversationId(state),
        message,
      })
    );
  };
};
