import PubNub from "pubnub";
import keyConfiguration from "./pubnub-keys.js";

const pubnubConfig = Object.assign(
  {},
  {
    // Ensure that subscriptions will be retained if the network connection is lost
    restore: true,
    heartbeatInterval: 0,
  },
  keyConfiguration
);
export const pubnub = new PubNub(pubnubConfig);
