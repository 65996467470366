import { auth } from "../../../services/firebase";
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { BackendEndpoint } from "../../../constants";
import { VoteCount, VoteKind } from "../VoteCount";
import { Reaction } from "../reactionTypes";

const RatingsContainer = styled.div<{ vertical?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.vertical ? "column" : "row")};
  gap: 1em;
  flex-wrap: wrap;
  align-content: space-evenly;
`;

export type VoteValue = VoteKind | "none";

interface RatingsProps {
  upvotes: number;
  downvotes: number;
  onVote?: (voteKind: VoteKind) => any; // Signifies this Rating component can be used for voting
  voteValue?: VoteValue; // Typically used to set the value of this component through onVote
  vertical?: boolean;
}

export const Ratings: FC<RatingsProps> = ({
  upvotes,
  downvotes,
  onVote,
  voteValue,
  vertical,
}) => {
  const handleUpvote = onVote ? () => onVote("up") : undefined;
  const handleDownvote = onVote ? () => onVote("down") : undefined;

  return (
    <RatingsContainer vertical={vertical}>
      <VoteCount
        kind="up"
        count={upvotes}
        onClick={handleUpvote}
        highlighted={voteValue === "up"}
      />
      <VoteCount
        kind="down"
        count={downvotes}
        onClick={handleDownvote}
        highlighted={voteValue === "down"}
      />
    </RatingsContainer>
  );
};

// Convenience function for setting a rating.
// ASSUMPTION: the currentUser is not undefined/null
export function voteOnReaction(
  voteKind: VoteKind,
  reactionid: Reaction["reactionid"]
) {
  const currentUserUid = auth.currentUser!.uid;

  return fetch(
    `${BackendEndpoint}api/v1/users/${currentUserUid}/votes/${reactionid}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        voteKind,
      }),
      credentials: "include",
    }
  )
    .then(() => {
      // 
    })
    .catch((e) => {
      console.error(e);
    });
}

// Hook to retrieve the vote value of a particular reaction
// ASSUMPTION: the currentUser is not undefined/null
export function useReactionVoteValue(reaction: Reaction | undefined) {
  const [voteValue, setVoteValue] = useState<VoteValue>("none");

  useEffect(() => {
    if (reaction) {
      const currentUser = auth.currentUser;
      if (currentUser != null) {
        const currentUserUUID = currentUser.uid;
        const reactionid = reaction.reactionid;

        fetch(
          `${BackendEndpoint}api/v1/users/${currentUserUUID}/votes/${reactionid}`
        )
          .then((res) => res.json())
          .then(
            (result) => { },
            (error) => { }
          );
      }
    }
  }, [reaction]);

  // A function for changing the voteValue;
  // if the voteValue is already the given voteKind, undo the vote, otherwise, set voteKind = voteValue
  function setOrUndoVote(voteKind: VoteKind) {
    if (voteValue === voteKind) {
      setVoteValue("none");
    } else {
      setVoteValue(voteKind);
    }
  }

  return { voteValue, setOrUndoVote };
}
