import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  ProfilePicture,
  sizeToDimensionLength,
} from "../../../components/ProfilePicture";
import { DefaultTopicChip } from "../../../components/TopicChips";
import { Ratings } from "../components/Ratings";
import { Post } from "../reactionTypes";
import { formatDistance } from "date-fns";

const profilePictureSize = "small";

const PostSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const PostAuthoringDetails = styled.div`
  color: #cdcdcd;
  :hover {
    color: gray;
    text-decoration: underline;
  }
`;
const PostSummaryMidsection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;
const PostTitle = styled.div`
  flex: 2;
`;
const PostResponsesLink = styled.div`
  color: #cdcdcd;
  :hover {
    color: gray;
    text-decoration: underline;
  }
`;

const CommentHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const CommentInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
`;

const CommentControls = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
`;

const CommentText = styled.div``;

const IndentedCommentListContainer = styled.div`
  margin-left: 1em;
`;

interface CommentComponentProps {
  post: Post;
  postToUserMap: Map<Post, any>;
}

const TopicList = styled.div`
  display: flex;
  flex: 3;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5em;
  margin-bottom: 1em;
`;

export const PostSummary: FC<CommentComponentProps> = ({
  post,
  postToUserMap,
}) => {
  const history = useHistory();
  const user = postToUserMap.get(post);

  const postdateDistance = formatDistance(
    new Date(post.reaction.postdate),
    Date.now(),
    {
      addSuffix: true,
    }
  );

  // If author is loading, show ...
  const postMetadata = user
    ? `Posted ${postdateDistance} by ${user.FirstName} ${user.LastName} (${user.Username})`
    : "...";

  return (
    <PostSummaryContainer>
      <PostAuthoringDetails
      // onClick={(event) => {
      //   event.stopPropagation();
      //   history.push(`history/${post.reaction.authorUUID}`);
      // }}
      >
        {postMetadata}
      </PostAuthoringDetails>
      <PostSummaryMidsection>
        <PostTitle>{post.title}</PostTitle>
        <TopicList>
          {post.topics?.map(
            ({ Topicid: topicId, Name: name, Color: color }) => (
              <DefaultTopicChip key={topicId} color={color}>
                {name}
              </DefaultTopicChip>
            )
          )}
        </TopicList>
      </PostSummaryMidsection>
      {/* TODO: Change to use the # of responses: e.x., "6 responses" */}
      <PostResponsesLink>See responses {`>`}</PostResponsesLink>
    </PostSummaryContainer>
  );
};
