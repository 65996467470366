import Search from "@mui/icons-material/Search";
import React from "react";
import styled from "styled-components";
import TextField from "@mui/material/TextField";

const SearchBarContainer = styled.div`
  display: grid;
  grid-template-columns: auto 2fr;
  align-items: center;
  gap: 0.5rem;

  border-radius: 2rem;
  outline: 1px solid #ced4da;
  padding: 0.5rem 1rem 0.5rem 1rem;

  :focus-within {
    outline: 2px solid #011d84;
  }
`;

const SearchInput = styled.input`
  border: none;
  :focus {
    outline: none;
  }
`;

export const SearchBar = ({
  placeholder,
  searchBarText,
  onChange,
}: {
  placeholder: string;
  searchBarText: string;
  onChange: (s: string) => void;
}) => (
  <SearchBarContainer>
    <Search />
    <SearchInput
      placeholder={placeholder}
      value={searchBarText}
      onChange={(event) => {
        onChange(event.target.value);
      }}
    />
  </SearchBarContainer>
);
