import ReplyIcon from "@mui/icons-material/Reply";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import IconButton from "@mui/material/IconButton";
import { Button as BootstrapButton } from "react-bootstrap";
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { ProfilePicture } from "../../../components/ProfilePicture";
import { useAppSelector } from "../../../config/redux-hooks";
import {
  Ratings,
  useReactionVoteValue,
  voteOnReaction,
} from "../components/Ratings";
import { Comment } from "../reactionTypes";
import { CommentList } from "./CommentList";
import { getCommentsByParentId, getCommentToUserMap } from "./postPageSlice";
import { ReactionEditor } from "../ReactionEditor";
import ReactMarkdown from "react-markdown";
import { BackendEndpoint } from "../../../constants";
import { useDispatch } from "react-redux";
import { auth } from "../../../services/firebase";
import { useHistory } from "react-router-dom";
import { formatDistance } from "date-fns";

const profilePictureSize = "small";

const CommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const CommentMetadata = styled.div`
  color: #9ea3a9;
`;

const CommentHeader = styled.div`
  color: #747474;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const CommentInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
`;

const CommentControls = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
`;

const CommentText = styled(ReactMarkdown)`
  // +1 because of the "gap" in CommentInfo
  margin-left: 4em;
  p {
    margin: 0;
  }
`;

const IndentedCommentListContainer = styled.div`
  margin-left: 4em;
`;

const ReplyArea = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */

  margin-bottom: 1rem;
`;

const Button = styled(BootstrapButton)`
  margin-top: 0;
`;

interface CommentComponentProps {
  comment: Comment;
}

export const CommentComponent: FC<CommentComponentProps> = ({ comment }) => {
  const [isBeingRepliedTo, setIsBeingRepliedTo] = useState<boolean>(false);
  const [newCommentText, setNewCommentText] = useState<string>("");

  const dispatch = useDispatch();
  const history = useHistory();

  const { voteValue, setOrUndoVote } = useReactionVoteValue(comment.reaction);
  const children = useAppSelector(
    (state) => getCommentsByParentId(state)[comment.reaction.reactionid]
  );
  const commentToUserMap = useAppSelector((state) =>
    getCommentToUserMap(state)
  );

  function createComment(parentid: number, newCommentText: string) {
    const timestamp = new Date().toISOString(); // TODO: Change this to a better time library
    const currentUserFirebaseUID = auth.currentUser!.uid; // Assumption: only called when the user is logged in

    fetch(`${BackendEndpoint}api/v1/comments`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        authorUUID: currentUserFirebaseUID,
        parentid,
        text: newCommentText,
        timestamp,
      }),
      credentials: "include",
    })
      .then(() => {
        setNewCommentText("");
        history.go(0);

      })
      .catch((e) => {
        console.error(e);
      });
  }

  const postdateDistance = formatDistance(
    new Date(comment.reaction.postdate),
    Date.now(),
    {
      addSuffix: true,
    }
  );

  // If a comment's author is loading, show ...
  const commentAuthor = commentToUserMap[comment.reaction.reactionid];
  const commentMetadata = commentAuthor
    ? `Posted ${postdateDistance} by ${commentAuthor.FirstName} ${commentAuthor.LastName} (${commentAuthor.Username})`
    : "...";

  return (
    <CommentContainer>
      <div>
        <CommentHeader>
          <CommentInfo>
            <ProfilePicture
              size={profilePictureSize}
              src={
                commentToUserMap[comment.reaction.reactionid]?.ProfilePicture
                  .String
              }
            />
            <CommentMetadata>{commentMetadata}</CommentMetadata>
            <Ratings
              onVote={
                auth.currentUser
                  ? (voteKind) => {
                    voteOnReaction(voteKind, comment.reaction.reactionid);
                    setOrUndoVote(voteKind);
                  }
                  : undefined
              }
              voteValue={voteValue}
              upvotes={comment.reaction.upvotes}
              downvotes={comment.reaction.downvotes}
            />
          </CommentInfo>
          <CommentControls>
            <IconButton
              onClick={() => {
                setIsBeingRepliedTo((oldValue) => !oldValue);
              }}
            >
              <ReplyIcon />
            </IconButton>
            <IconButton>
              <FolderSpecialIcon />
            </IconButton>
            <IconButton>
              <MoreHorizIcon />
            </IconButton>
          </CommentControls>
        </CommentHeader>
        <CommentText>{comment.reaction.text}</CommentText>
      </div>
      {isBeingRepliedTo && (
        <ReplyArea>
          <ReactionEditor
            text={newCommentText}
            onChange={setNewCommentText}
            childProps={{
              textArea: {
                placeholder:
                  "Replying to " +
                  commentAuthor.FirstName +
                  " " +
                  commentAuthor.LastName,
              },
            }}
          />
          <Button
            disabled={newCommentText === ""}
            variant="secondary"
            onClick={() => {
              createComment(comment.reaction.reactionid, newCommentText);
            }}
          >
            Submit
          </Button>
        </ReplyArea>
      )}
      {children && (
        <IndentedCommentListContainer>
          <CommentList parentId={comment.reaction.reactionid} />
        </IndentedCommentListContainer>
      )}
    </CommentContainer>
  );
};
