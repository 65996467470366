import React, { Component } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import Home from "./features/Home";
import Chat from "./features/chat/Chat";
import Signup from "./features/authentication/Signup";
import Login from "./features/authentication/Login";
import { Search } from "./features/Search.tsx";
import Profile from "./features/authentication/Profile";
import { auth } from "./services/firebase";
import "./styles.css";

import { Provider } from "react-redux";

import { PubNubProvider } from "pubnub-react";
import { store } from "./config/store";
import { createPubNubListener, createMembershipListener } from "pubnub-redux";
import styled from "styled-components";

import { login } from "./features/authentication/loginCommand.js";
import { logout } from "./features/authentication/logoutCommand";
import { Header } from "./components/Header";
import { pubnub } from "./config/pubnubConfig";
import { ForumRoutes } from "./features/forum/ForumRoutes";
import { InfohubRoutes } from "./features/infohub/InfohubRoutes";
import { ChatPage } from "./features/chat/ChatPage";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";

const leaveApplication = () => {
  // This is required to show the current user leave immediately rather than
  // wating for the timeout period
  pubnub.unsubscribeAll();
};

export function PrivateRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated === true ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { ...props.location.state } }}
          />
        )
      }
    />
  );
}

export function PublicRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated === false || props.location.pathname == "/signup" ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/search",
              state: { ...props.location.state },
            }}
          />
        )
      }
    />
  );
}

// Container to allow pages to make use of percentage height
const LayoutContainer = styled.div`
  /* display: flex;
  flex-flow: column;
  height: 100%; */
  display: grid;
  height: 100vh;
  grid-template-rows: auto minmax(0, 1fr);
`;

class App extends Component {
  constructor() {
    super();
    this.state = {
      authenticated: false,
      mentorChosen: false,
      loading: true,
    };
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          authenticated: true,
          loading: false,
        });
        store.dispatch(login(user.uid));
      } else {
        this.setState({
          authenticated: false,
          loading: false,
        });
        store.dispatch(logout());
      }
    });

    pubnub.addListener(createPubNubListener(store.dispatch));
    pubnub.addListener(createMembershipListener(store.dispatch));

    window.addEventListener("beforeunload", leaveApplication);
  }

  render() {
    return this.state.loading === true ? (
      <div className="spinner-border text-success" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    ) : (
      <Provider store={store}>
        <PubNubProvider client={pubnub}>
          <ThemeProvider theme={theme}>
            <Router>
              <LayoutContainer>
                <Header />
                <Switch>
                  <Route exact path="/" component={Home} />
                  <PrivateRoute
                    path="/chat"
                    authenticated={this.state.authenticated}
                    component={ChatPage}
                  />
                  <PrivateRoute
                    path="/search"
                    authenticated={this.state.authenticated}
                    component={Search}
                  />
                  <PrivateRoute
                    path="/profile"
                    authenticated={this.state.authenticated}
                    component={Profile}
                  />
                  <PublicRoute
                    path="/signup"
                    authenticated={this.state.authenticated}
                    component={Signup}
                  />
                  <PublicRoute
                    path="/login"
                    authenticated={this.state.authenticated}
                    component={Login}
                  />
                  <PublicRoute
                    path="/search"
                    authenticated={this.state.authenticated}
                    component={Search}
                  />
                  <Route path="/mentorship">
                    <ForumRoutes authenticated={this.state.authenticated} />
                  </Route>
                  <Route path="/infohub" component={InfohubRoutes} />
                  <Route path="*">
                    Sorry, we can't seem to find that page! [404]
                  </Route>
                </Switch>
              </LayoutContainer>
            </Router>
          </ThemeProvider>
        </PubNubProvider>
      </Provider>
    );
  }
}

export default App;
