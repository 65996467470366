import React, { FC, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import styled from "styled-components";
import {
  AddableTopicChip,
  AddTopicChip,
  RemovableTopicChip,
} from "../../components/TopicChips";
import { BackendEndpoint } from "../../constants";
import { Topic } from "./Topic";

const ControlHeading = styled.div`
  margin-bottom: 0.5em;
`;

const TopicList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5em;
  margin-bottom: 1em;
`;

const CustomToggle = React.forwardRef(
  (
    { children, onClick }: { children?: React.ReactNode; onClick: any },
    ref
  ) => (
    <AddTopicChip ref={ref} onClick={onClick}>
      Add Topic
    </AddTopicChip>
  )
);

interface TopicControlsProps {
  removeSelectedTopicAtIndex: (index: number) => void;
  addSelectedTopic: (topic: Topic) => void;
  selectedTopics: Topic[];
}

function useTopics() {
  const [topics, setTopics] = useState<Topic[]>([]);
  useEffect(() => {
    fetch(`${BackendEndpoint}api/v1/topics`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((rawResponse) => rawResponse.json())
      .then(({ data }) => {
        setTopics(data);
      })
      .catch((e) => console.error("Topics could not be retrieved: " + e));
  }, []);
  return topics;
}

export const TopicControls: FC<TopicControlsProps> = ({
  removeSelectedTopicAtIndex,
  addSelectedTopic,
  selectedTopics,
}) => {
  const topicOptions = useTopics();

  useEffect(() => {

  }, [selectedTopics]);
  const suggestedTopics = topicOptions.slice(-3);

  return (
    <>
      <ControlHeading>Topics:</ControlHeading>
      <TopicList>
        {selectedTopics.map(
          (
            { Topicid: topicId, Name: name, Color: color },
            selectedTopicIndex
          ) => (
            <RemovableTopicChip
              key={topicId}
              color={color}
              onClick={() => {
                removeSelectedTopicAtIndex(selectedTopicIndex);
              }}
            >
              {name}
            </RemovableTopicChip>
          )
        )}
        <Dropdown title="Add Topic">
          <Dropdown.Toggle as={CustomToggle} />
          <Dropdown.Menu>
            {topicOptions.map((topic, index) => (
              <Dropdown.Item
                key={topic.Topicid}
                onSelect={() => {
                  const topicHasAlreadyBeenSelected = selectedTopics.some(
                    ({ Topicid: topicId }) => topicId === topic.Topicid
                  );
                  if (!topicHasAlreadyBeenSelected) {
                    addSelectedTopic(topic);
                  }
                }}
              >
                {topic.Name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </TopicList>
      <ControlHeading>Suggested Topics:</ControlHeading>
      <TopicList>
        {suggestedTopics.map((topicOption, selectedTopicIndex) => {
          const topicHasAlreadyBeenSelected = selectedTopics.some(
            ({ Topicid: topicId }) => topicId === topicOption.Topicid
          );
          if (!topicHasAlreadyBeenSelected) {
            return (
              <AddableTopicChip
                key={topicOption.Topicid}
                color={topicOption.Color}
                onClick={() => {
                  addSelectedTopic(topicOption);
                }}
              >
                {topicOption.Name}
              </AddableTopicChip>
            );
          } else {
            return null;
          }
        })}
      </TopicList>
    </>
  );
};
