import { createMembershipReducer } from "pubnub-redux";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { RootState } from "../../config/store";

const getJoinedConversationsSlice = (state: RootState) =>
  state?.joinedConversations;

export const getConversationsByUserId = createSelector(
  [getJoinedConversationsSlice],
  (conversations) => {
    return conversations?.byId;
  }
);

export function useConversationsForUser(userUID: string) {
  const conversationsByUserId = useSelector(getConversationsByUserId);
  return conversationsByUserId?.[userUID] ?? []; // Return empty array if the convo data is missing or if the user has no convos
}

export const JoinedConversationsStateReducer = createMembershipReducer();
