import { initializeApp } from "firebase/app"
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";

const config = {
  apiKey: "AIzaSyBrKv-gk62CAUlj7B5ZQ45JESxhTy3KwL4",
  authDomain: "caat-14aa0.firebaseapp.com",
  databaseURL: "https://caat-14aa0.firebaseio.com",
  projectId: "caat-14aa0"
};

const firebaseApp = initializeApp(config);

export const auth = getAuth(firebaseApp);
export const db = getDatabase(firebaseApp);
export const firestore = getFirestore(firebaseApp);


export default firebaseApp;