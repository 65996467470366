import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { signin, signInWithGoogle, getUserProfile } from "./auth";
import { login } from "./loginCommand.js";
import pressPlayPicture from "../../assets/pressPlay.svg";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      error: null,
      email: "",
      password: "",
      name: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.googleSignIn = this.googleSignIn.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ error: "" });
    try {
      let {
        user: { uid },
      } = await signin(this.state.email, this.state.password, this.state.name);
      let userProfile = await getUserProfile();
      
    } catch (error) {
      this.setState({ error: error.message });
    }
  }

  async googleSignIn() {
    try {
      let user = await signInWithGoogle();
      let userProfile = await getUserProfile();
      
    } catch (error) {
      this.setState({ error: error.message });
    }
  }

  render() {
    return (
      <div
        className="container"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <form
          className="mt-5 py-5 px-5 w-auto"
          autoComplete="off"
          onSubmit={this.handleSubmit}
        >
          <h1>
            Login to
            <Link className="title ml-2" to="/">
              uConverse
            </Link>
          </h1>
          <p className="lead">
            Fill in the form below to login to your account.
          </p>
          <div className="form-group">
            <input
              className="form-control"
              placeholder="Email"
              name="email"
              type="email"
              onChange={this.handleChange}
              value={this.state.email}
            />
          </div>
          <div className="form-group">
            <input
              className="form-control"
              placeholder="Password"
              name="password"
              onChange={this.handleChange}
              value={this.state.password}
              type="password"
            />
          </div>
          <div className="form-group">
            {this.state.error ? (
              <p className="text-danger">{this.state.error}</p>
            ) : null}
            <button className="btn btn-primary px-5" type="submit">
              Login
            </button>
          </div>
          <p>You can also log in with any of these services</p>
          <button
            className="btn btn-danger mr-2"
            type="button"
            onClick={this.googleSignIn}
          >
            Sign in with Google
          </button>
          <hr />
          <p>
            Don't have an account? <Link to="/signup">Sign up</Link>
          </p>
        </form>
        <img src={pressPlayPicture} style={{ height: "75%" }} />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (uuid) => dispatch(login(uuid)),
  };
};

export default connect(null, mapDispatchToProps)(Login);
