import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { FC, MouseEventHandler, MutableRefObject } from "react";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import styled from "styled-components";

const ChipButton = styled(Button)`
  display: grid;
  grid-template-columns: ${(props) =>
    (props.iconVariant as GenericChipProps["iconVariant"]) === "default"
      ? "1fr"
      : "1fr auto"};
  align-items: center;
  gap: 0.25rem;

  border-radius: 50px;
  color: white;

  :hover {
    /* If there's no onClick handler, then don't force a 'pointer' style cursor */
    cursor: ${(props) => (props.onClick ? "pointer" : "inherit")};
  }

  &:focus {
    box-shadow: none;
  }
  margin: 0;
`;

type GenericChipIconVariant = "default" | "addable" | "removable";

type GenericChipProps = {
  style?: React.CSSProperties;
  children?: string;
  onClick?: MouseEventHandler;
  iconVariant: GenericChipIconVariant;
};

function getIconForVariant(iconVariant: GenericChipIconVariant) {
  switch (iconVariant) {
    case "default":
      return null;
    case "addable":
      return <AddCircleIcon fontSize="inherit" />;
    case "removable":
      return <CancelIcon fontSize="inherit" />;
  }
}

export const GenericChip = React.forwardRef(
  ({ style, children, onClick, iconVariant }: GenericChipProps, ref) => {
    return (
      <ChipButton
        iconVariant={iconVariant}
        ref={ref}
        onClick={onClick}
        style={style}
        size="sm"
      >
        {children}
        {getIconForVariant(iconVariant)}
      </ChipButton>
    );
  }
);
