import { InstitutionInfo } from "../Institution";

export function getMemorialUniversity(): InstitutionInfo {
  return { 
    institutionId: 4,
    name: "Memorial University",
    isHighSchool: false,
    logoImage: "https://upload.wikimedia.org/wikipedia/commons/d/dc/Memorial_University_of_Newfoundland_Logo.svg",
    institudeDescription: "Memorial University of Newfoundland, also known as Memorial University or MUN, is a public university in the province of Newfoundland and Labrador, based in St. John's, with satellite campuses in Corner Brook, elsewhere in Newfoundland and in Labrador, Saint Pierre, and Harlow, England.",
    moreInfoLink: "https://www.mun.ca/main/about/",
    instituteAddress: "Memorial University of Newfoundland, St. John's, NL A1C 5S7, P.O. Box 4200",
    institutePhone: "709-864-8000",
    admissionsQuickLink: "https://www.mun.ca/undergrad/admissions/",
    undergraduateQuickLink: "https://www.mun.ca/undergrad/programs/", 
    tutionQuickLink: "https://www.mun.ca/undergrad/money-matters/",
    housingQuickLink: "https://www.mun.ca/residences/",
    campusQuickLink: "https://www.mun.ca/undergrad/campus-and-city-life/",
    coopcareerQuickLink: "https://www.mun.ca/coop/",
    graduateQuickLink: "https://www.mun.ca/become/graduate/programs-and-courses/",
    faqQuickLink: "https://www.mun.ca/engineering/graduate/prospective-students/frequently-asked-questions/"
  }
  ;
}