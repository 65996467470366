import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import IconButton from "@mui/material/IconButton";
import React, { FC } from "react";
import styled from "styled-components";

interface ScrollToTopButtonProps {
  handleScrollToTop: () => void;
}

const CircularButton = styled(IconButton)`
  right: 0.5rem; /* Place the button 30px from the right */
  bottom: 0.25rem;
  z-index: 99; /* Make sure it does not overlap */
  /* width: 50px;
  height: 50px; */
  border-radius: 25px;
`;

export const ScrollToTopButton: FC<ScrollToTopButtonProps> = ({
  handleScrollToTop,
}) => {
  return (
    <CircularButton
      sx={{
        bgcolor: "primary.main",
        color: "primary.contrastText",
        "&:hover": {
          color: "primary.main",
        },
      }}
      onClick={handleScrollToTop}
    >
      <ArrowUpwardIcon
        sx={{
          color: "inherit",
        }}
      />
    </CircularButton>
  );
};
