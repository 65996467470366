import {
  setUserData,
  fetchUserData,
  fetchMemberships,
  fetchAllUserData,
} from "pubnub-redux";
import { loggingIn, loginSucceeded } from "./authenticationModel";
import { getConversationsByUserId } from "../chat/joinedConversationModel";
import { BackendEndpoint } from "../../constants";
import { setTokenRefreshTimer } from "./auth";

// TODO: Break into smaller, easier to understand functions

export const login = (uuid) => {
  return (dispatch, getState, context) => {
    dispatch(loggingIn());

    
    // Show the login screen for a minimum amount of time as a splash screen
    //const timer = new Promise(resolve => setTimeout(resolve, 2000));

    // Set the UUID of the current user to ensure that presence works correctly
    context.pubnub.api.setUUID(uuid);
    
    const isLoginSuccessful = context.pubnub.api.objects
      .setUUIDMetadata({
        data: {},
      })
      .then(() => {
        

        // ensure that the current user exists while also populating the store
        // with their information.
        return dispatch(fetchUserData({ uuid }))
          .then(() => {
            
            // Subscribe to the user's channel to receive events involving this user
            context.pubnub.api.subscribe({
              channels: [uuid],
              withPresence: true,
            });
            
          })
          .then(() => {
            
            return dispatch(
              // Load the conversations that this user has joined
              fetchMemberships({
                uuid: uuid,
                include: {
                  channelFields: true,
                  customChannelFields: false,
                  customFields: false,
                  totalCount: false,
                },
              })
            );
          })
          .then(() => {
            // Subscribe to messages on the user's joined conversations
            const conversationChannels = getConversationsByUserId(getState())[
              uuid
            ].map((membership) => membership.id);

            

            context.pubnub.api.subscribe({
              channels: conversationChannels,
              withPresence: true,
            });
            
          })
          .then(() => {
            return dispatch(fetchAllUserData()); // TODO: change to a more palatable dataset; right now it also updates "users"
          });
      })
      .then(() => {
        
        fetch(`${BackendEndpoint}api/v1/login/1`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            // INSECURE: TODO - REPLACE WITH CORS and ID TOKEN VERIFICATION
            password:
              "47TYfWW8mxCfqKEqAFbOQyxdWIkmxwb13onH475bH1otDU4uZyLQ80Hit",
          }),
          credentials: "include",
        }).then(() => {
          setTokenRefreshTimer();
        });
      });

    return Promise.all([isLoginSuccessful]).then(() => {
      
      dispatch(loginSucceeded({ loggedInUserId: uuid }));
    });
  };
};
