import { RefObject, useEffect, useState } from "react";

// Detects if the element referred to by ref is visible onscreen
export default function useOnScreen(ref: RefObject<HTMLElement>) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting)
  );

  useEffect(() => {
    // ref.current is guaranteed not to be null here, because useEffect runs after components mount.
    observer.observe(ref.current!);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
}
