import { unsetTokenRefreshTimer } from "./auth";
import { loggingOut } from "./authenticationModel";

export const logout = () => {
  return (dispatch, getState, context) => {
    dispatch(loggingOut());
    context.pubnub.api.unsubscribeAll();
    unsetTokenRefreshTimer();
  };
};
