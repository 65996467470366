import React from "react";
import styled from "styled-components";
import { ProfilePicture } from "../../components/ProfilePicture";

const QuickLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 0.5em;
`;

interface QuickLinkProps {
  src: string;
  name: string;
  image: string;
}

export const QuickLink = ({ src, name, image }: QuickLinkProps) => {
  return (
    <QuickLinkContainer>
      <a href={src}>{name}</a>
      <ProfilePicture size="small" src= {image} />
      {/* <ProfilePicture size="small" src="https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png" /> */}
    </QuickLinkContainer>
  );
};
