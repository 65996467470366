import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import IconButton from "@mui/material/IconButton";
import React, { MouseEventHandler } from "react";
import { Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Ratings } from "../components/Ratings";
import { Post } from "../reactionTypes";
import { PostSummary } from "./PostSummary";

const PostListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const PostListItem = styled(Card)`
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 1rem;
  justify-items: start;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  :hover {
    outline-style: solid;
    outline-color: gray;
    outline-width: 1px;
  }
  margin-left: 1px; // To allow the hover effect above to be seen
`;

interface PostListProps {
  posts: Post[];
  postToUserMap: Map<Post, any>;
}

const handleMoreClick: MouseEventHandler = (event) => {
  event.stopPropagation();
};

export function PostList({ posts, postToUserMap }: PostListProps) {
  const history = useHistory();
  return (
    <PostListContainer>
      {posts.map((post) => (
        <PostListItem
          onClick={() => {
            history.push(`posts/${post.reaction.reactionid}`);
          }}
        >
          <Ratings
            vertical
            upvotes={post.reaction.upvotes}
            downvotes={post.reaction.downvotes}
          />
          <PostSummary key={post.reaction.reactionid} post={post} postToUserMap={postToUserMap} />
          <IconButton onClick={handleMoreClick} size="large">
            <MoreHorizIcon fontSize="inherit" />
          </IconButton>
        </PostListItem>
      ))}
    </PostListContainer>
  );
}
