import React, { FC, useState, useEffect, ChangeEvent } from "react";
import styled from "styled-components";
import Footer from "../components/Footer";
import Spinner from "react-bootstrap/Spinner";
import { BackendEndpoint } from "../constants";
import { auth } from "../services/firebase";
import { joinConversation } from "./chat/pubnub/joinConversationCommand";
import { connect } from "react-redux";
import { useAuthState } from "react-firebase-hooks/auth";
import { useAppDispatch } from "../config/redux-hooks";
import { useHistory } from "react-router-dom";

const CardDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: center;
  justify-content: center;
`;

const ImageContainer = styled.div`
  width: 300px;
  height: 300px;
`;

const ImageStyle = styled.img`
  max-height: 100%;
  width: 100%;
  object-fit: cover;
  cursor: pointer;
`;

const TextContainer = styled.div`
  width: 300px;
`;


type User = any;

function useUsers(): {
  loading: boolean;
  error: any;
  users: User[];
} {
  const [_, authIsLoading, authError] = useAuthState(auth);

  // TODO: Set user to an actual type
  const [users, setUsers] = useState<User[]>([]);
  const [usersLoaded, setUsersLoaded] = useState<boolean>(false);
  const [usersEndpointError, setUsersEndpointError] = useState<any>(null);

  useEffect(() => {
    if (!authIsLoading && !authError) {
      fetch(`${BackendEndpoint}api/v1/users?isMentor=true`, {
        credentials: "include",
      })
        .then((res) => res.json())
        .then(
          (result) => {


            const receivedUsers: User[] = [];

            // TODO: Set user to an actual type
            // Wait for all the contained promises to finish
            Promise.all(
              // .map instead of forEach so that we return a list of Promises
              result.data.map((user: any) => {
                fetch(
                  `${BackendEndpoint}api/v1/enrollment/users/${user.Uuid.String}`,
                  { credentials: "include" }
                )
                  .then((res) => res.json())
                  .then(
                    (result) => {
                      if (result.data) {
                        // Mutate the current user
                        user.IsMentor = result.data[0].IsMentor.Bool;
                        user.Program = result.data[0].Program.String;
                        user.Institution = result.data[0].Name;

                        setUsers((oldUsers) => [...oldUsers, user]);
                      }
                    },
                    (error) => {
                      // Possible race - assumption: doesn't matter which error is returned, we need to diagnose them anyway
                      setUsersEndpointError(error);
                      // Possible race - assumption: doesn't matter if the other requests are running, we received an error (and it has been set)
                      setUsersLoaded(true);
                    }
                  );
              })
            ).then(() => {
              setUsersLoaded(true); // Can race here; assumption: the users are loaded exactly once
            });

            setUsers(receivedUsers);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            setUsersLoaded(true);
            setUsersEndpointError(error);
          }
        );
    }
  }, [authIsLoading, authError]);

  return {
    loading: !usersLoaded,
    error: authError ?? usersEndpointError,
    users: users,
  };
}

export const Search: FC = () => {
  // const { error, isLoaded, users } = this.state;
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { loading, error, users } = useUsers();

  const [selectedInstitution, setSelectedInstitution] = useState<string>("All");
  const [selectedProgram, setSelectedProgram] = useState<string>("All");

  if (error) {
    return (
      <div className="search">
        <section>
          <div className="jumbotron jumbotron-fluid py-5">
            <div className="container text-center py-5">
              <h1 className="display-4">Our Mentors</h1>
              <div className="mt-4">
                Click on the mentors to start chatting with them.
              </div>
              <div>Error: {error.message}</div>
            </div>
          </div>
        </section>
        <Footer></Footer>
      </div>
    );
  } else if (loading) {
    return (
      <div className="search">
        <section>
          <div className="jumbotron jumbotron-fluid py-5">
            <div className="container text-center py-5">
              <h1 className="display-4">Our Mentors</h1>
              <div className="mt-4">
                Click on the mentors to start chatting with them.
              </div>
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          </div>
        </section>
        <Footer></Footer>
      </div>
    );
  } else {
    let programs: string[] = users.map((user) => user.Program.trim());
    programs = [...Array.from(new Set(programs))];
    programs = programs.sort();
    programs.unshift("All")

    let institutions: string[] = users.map((user: any) => user.Institution.trim());
    institutions = [...Array.from(new Set(institutions))];
    institutions = institutions.sort();
    institutions.unshift("All")
    return (
      <div className="search">
        <section>
          <div className="jumbotron jumbotron-fluid py-5">
            <div className="container text-center py-5">
              <h1 className="display-4">Our Mentors</h1>
              <div className="mt-4">
                Click on the mentors to start chatting with them.
                <br></br>
                <br></br>
              </div>
              <div className="row-auto">
                <div className="column">
                  <label>
                    University &nbsp;&nbsp;
                    <select
                      name="institution"
                      value={selectedInstitution}
                      onChange={(event) => {
                        setSelectedInstitution(event.target.value);
                      }}
                    >
                      {institutions.map((institution) => (
                        <option value={institution} key={institution}>
                          {institution}
                        </option>
                      ))}
                    </select>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </label>
                </div>
                <div className="column">
                  <label>
                    Program &nbsp;&nbsp;
                    <select
                      name="program"
                      value={selectedProgram}
                      onChange={(event) => {
                        setSelectedProgram(event.target.value);
                      }}
                    >
                      {programs.map((program) => (
                        <option value={program} key={program}>
                          {program}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
              </div>
            </div>
            <div className="col text-center">
              <button className="btn btn-primary" type="submit">
                Search
              </button>
            </div>
          </div>
        </section>
        <section>
          <CardDiv className="flex-container">
            {users
              .filter((user: any) =>
                user.IsMentor === false
                  ? false
                  : // if we are the most general filter, show all
                  selectedProgram === "All" && selectedInstitution === "All"
                    ? true
                    : // if both filters are set, filter accordingly
                    selectedProgram !== "All" && selectedInstitution !== "All"
                      ? selectedProgram === user.Program.trim() &&
                      selectedInstitution === user.Institution.trim()
                      : // otherwise, filter by the one that is set
                      selectedProgram !== "All"
                        ? selectedProgram === user.Program.trim()
                        : selectedInstitution !== "All"
                          ? selectedInstitution === user.Institution.trim()
                          : false
              )
              .map((user) => (
                <div key={user.Userid}>
                  <section
                    onClick={(e) => {
                      const currentUser = auth.currentUser!; // To be in this section, auth should have succeeded
                      const conversationId = [currentUser.uid, user.Uuid.String]
                        .sort((a, b) => {
                          if (a === b) {
                            return 0;
                          } else if (a > b) {
                            return 1;
                          } else {
                            return -1;
                          }
                        }).join("&")
                      dispatch(
                        joinConversation(currentUser.uid, conversationId)
                      );
                      history.push({
                        pathname: "/chat",
                      });
                    }}
                  >
                    <ImageContainer>
                      <ImageStyle src={user.ProfilePicture.String} />
                    </ImageContainer>
                  </section>
                  <TextContainer>
                    <section>
                      {`${user.FirstName} ${user.LastName}`}
                      <br></br>
                      {user.Institution}
                      <br></br>
                      {user.Program}
                    </section>
                  </TextContainer>
                </div>
              ))}
          </CardDiv>
        </section>
        <Footer></Footer>
      </div>
    );
  }
};
