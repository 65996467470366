import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const NavigationInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;
const PageTitle = styled.h1`
  display: inline;
`;
const BackMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

interface NavigationInfoProps {
  backMessage?: string;
  title?: string;
}

// May want to have a callback passed in that allows you to prevent going back if something on the page hasn't been saved/submitted
export const NavigationInfo: FC<NavigationInfoProps> = ({
  backMessage,
  title,
}) => {
  const history = useHistory();

  return (
    <NavigationInfoContainer>
      {title && <PageTitle>{title}</PageTitle>}
      {backMessage && (
        <BackMessage onClick={history.goBack}>
          <ArrowBackIos fontSize="inherit" />
          {backMessage}
        </BackMessage>
      )}
    </NavigationInfoContainer>
  );
};
