import { createSelector } from "reselect";
import invariant from "invariant";

export const LOGGING_IN = "LOGGING_IN";
export const loggingIn = () => ({
  type: LOGGING_IN,
});

export const LOGIN_SUCCEEDED = "LOGIN_SUCCEEDED";
export const loginSucceeded = (loginSucceededPayload) => ({
  type: LOGIN_SUCCEEDED,
  payload: loginSucceededPayload,
});

export const LOGGING_OUT = "LOGGING_OUT";
export const loggingOut = () => ({
  type: LOGGING_OUT,
});

const initialState = {
  isLoggingIn: false,
};

const AuthenticationStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGGING_IN: {
      return { ...state, isLoggingIn: true };
    }
    case LOGIN_SUCCEEDED: {
      return {
        ...state,
        isLoggingIn: false,
        loggedInUserId: action.payload.loggedInUserId,
      };
    }
    default:
      return state;
  }
};

const getAuthenticationStateSlice = (state) => state.authentication;

export const getLoggedInUserId = createSelector(
  [getAuthenticationStateSlice],
  (authenticationState) => {
    invariant(
      authenticationState.loggedInUserId !== undefined,
      "getLoggedInUserId should not be used in components of the application that are rendered while there is no logged in user"
    );
    return authenticationState.loggedInUserId;
  }
);

export const isUserLoggedIn = createSelector(
  getAuthenticationStateSlice,
  (authenticationState) => {
    return !!authenticationState.loggedInUserId;
  }
);

export const isLoggingIn = createSelector(
  getAuthenticationStateSlice,
  (authenticationState) => {
    return authenticationState.isLoggingIn;
  }
);

export { AuthenticationStateReducer };
