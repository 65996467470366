import React, { FC, useRef } from "react";
import { Card } from "react-bootstrap";
import styled from "styled-components";
import { ScrollToTopButton } from "../../components/ScrollToTopButton";

// TODO: Adjust to be responsive (e.x., using media queries)
const ForumControlsContainer = styled.div`
  padding: 1%;
  width: 20%;
  height: 92.5%; // Should be 100%, but this container is fixed which messes things up - hack to make things fit better on smaller screens
  position: fixed;
  right: 0;
  overflow: auto;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`;

const ControlsCard = styled(Card)`
  height: 90%;
  width: 100%;
`;

const ContentContainer = styled.div`
  margin: 1%;
  margin-right: 20%; // Matches the width of the ForumControlsContainer
  padding-right: 1%;
  overflow-y: auto;
  height: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  overflow: hidden; // Set as hidden so that the "forum content" can have its own scrollbar
`;

interface ForumLayoutProps {
  controlsComponent: React.ReactNode;
  contentComponent: React.ReactNode;
}

/**
 * This component outline the general layout of a Forum page; it provides areas for "main content", and a
 * "side area" that stays fixed, used for controls. The components used in these areas are accepted as props.
 */
export const ForumLayout: FC<ForumLayoutProps> = ({
  controlsComponent,
  contentComponent,
}) => {
  const contentContainerRef = useRef<HTMLDivElement>(null);
  return (
    <Container>
      <ForumControlsContainer>
        <ControlsCard body>{controlsComponent}</ControlsCard>
        <ScrollToTopButton
          handleScrollToTop={() => {
            contentContainerRef.current?.scrollTo(0, 0);
          }}
        />
      </ForumControlsContainer>
      <ContentContainer ref={contentContainerRef}>
        {contentComponent}
      </ContentContainer>
    </Container>
  );
};
