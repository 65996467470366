import { Institution } from "./Institution";
import { InstitutionInfo } from "./Institution";
import { getUniversityOfWaterloo } from "./InstituteInfoPages/UniversityOfWaterlooInfo";
import { getUniversityOfToronto } from "./InstituteInfoPages/UniversityOfTorontoInfo";
import { getUniversityOfBritishColumbia } from "./InstituteInfoPages/UniversityOfBritishColumbiaInfo";
import { getMemorialUniversity } from "./InstituteInfoPages/MemorialUniversityInfo";

export function getInstitutions(): Institution[] {
  const universityOfWaterloo = getUniversityOfWaterloo()
  const universityOfToronto = getUniversityOfToronto()
  const universityOfBritishColumbia = getUniversityOfBritishColumbia()
  const memorialUniversity = getMemorialUniversity()
  return[
    {institutionId: universityOfWaterloo.institutionId, name: universityOfWaterloo.name, isHighSchool: universityOfWaterloo.isHighSchool},
    {institutionId: universityOfToronto.institutionId, name: universityOfToronto.name, isHighSchool: universityOfToronto.isHighSchool},
    {institutionId: universityOfBritishColumbia.institutionId, name: universityOfBritishColumbia.name, isHighSchool: universityOfBritishColumbia.isHighSchool},
    {institutionId: memorialUniversity.institutionId, name: memorialUniversity.name, isHighSchool: memorialUniversity.isHighSchool},
  ];
}

export function getInstitution(institutionId: number): InstitutionInfo {
  if (institutionId == 1){
    return getUniversityOfWaterloo() 
  }
  else if(institutionId == 2){
    return getUniversityOfToronto()
  }
  else if(institutionId == 3){
    return getUniversityOfBritishColumbia();
  }
  else if(institutionId == 4){
    return getMemorialUniversity();
  }
  else{
    return {} as InstitutionInfo;
  }
}
