import React, { FC, useEffect, useState } from "react";
import { ForumLayout } from "../ForumLayout";
import "react-mde/lib/styles/css/react-mde-all.css";
import { PostControls } from "./PostControls";
import { PostContents } from "./PostContents";
import { useParams } from "react-router-dom";
import { Comment, Post } from "../reactionTypes";
import { BackendEndpoint } from "../../../constants";
import { useSelector } from "react-redux";
import postPageSlice, {
  updateCommentToUserMap,
  CommentSortCriterion,
  getCommentSortCriteria,
} from "./postPageSlice";
import { useAppDispatch } from "../../../config/redux-hooks";

// Returns a boolean indicating loading, the opened Post and its Comments
function usePostAndComments(commentSortCriterion: CommentSortCriterion): {
  loading: boolean;
  post?: Post;
  comments?: Comment[];
  user?: any;
} {
  const { id } = useParams<{ id: string | undefined }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [post, setPost] = useState<Post>();
  const [user, setUser] = useState<any>();
  const [comments, setComments] = useState<Comment[]>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetch(`${BackendEndpoint}api/v1/posts/${id}`, { credentials: "include" })
      .then((res) => res.json())
      .then(
        (result) => {
          setPost(result.data);


          fetch(
            `${BackendEndpoint}api/v1/users/${result.data!.reaction.authorUUID
            }`,
            { credentials: "include" }
          )
            .then((res) => res.json())
            .then(
              (result2) => {
                setUser(result2.data);
              },
              (error) => {
                console.error(error);
              }
            );
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.error(error);
        }
      )
      .then(() => {
        fetch(
          `${BackendEndpoint}api/v1/comments?recentAncestorId=${id}&sortCriterion=${commentSortCriterion}`,
          {
            credentials: "include",
          }
        )
          .then((res) => res.json())
          .then(
            (result) => {
              setComments(result.data || []);
              var receivedComments: Comment[] = result.data as Comment[];
              receivedComments.map((comment) => {
                fetch(
                  `${BackendEndpoint}api/v1/users/${comment!.reaction.authorUUID
                  }`,
                  { credentials: "include" }
                )
                  .then((res) => res.json())
                  .then(
                    (result2) => {
                      dispatch(
                        updateCommentToUserMap({
                          reactionid: comment.reaction.reactionid,
                          user: result2.data,
                        })
                      );
                      // 
                    },
                    (error) => {
                      console.error(error);
                    }
                  );
              });
              setLoading(false);
            },
            (error) => {
              console.error(error);
            }
          );
      });
  }, [commentSortCriterion]);
  return { loading, post, comments, user };
  // return [loading, post, comments];
}

function useTopFourPosts(): {
  loading: boolean;
  topFourPosts?: Post[];
} {
  const { id } = useParams<{ id: string | undefined }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [posts, setPosts] = useState<Post[]>();

  useEffect(() => {
    fetch(`${BackendEndpoint}api/v1/posts?sortCriterion=top`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // Hmmmm, this is being called twice for some reason

          setPosts([...result.data.posts]);
          setLoading(false);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.error(error);
        }
      );
  }, []);
  return { loading, topFourPosts: posts?.slice(0, 4) };
  // return [loading, post, comments];
}

export const PostPage: FC = ({ }) => {
  const commmentSortCriterion = useSelector(getCommentSortCriteria);
  const { loading, post, comments, user } = usePostAndComments(
    commmentSortCriterion
  );

  const { loading: topPostsLoading, topFourPosts } = useTopFourPosts();


  return (
    <ForumLayout
      contentComponent={
        loading ? (
          "Loading..."
        ) : (
          <PostContents post={post!} comments={comments!} user={user} />
        )
      }
      controlsComponent={
        loading || topPostsLoading ? (
          "Loading..."
        ) : (
          <PostControls post={post!} suggestedPosts={topFourPosts!} />
        )
      }
    />
  );
};
