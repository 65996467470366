import React, { FC, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Button, Card, Dropdown } from "react-bootstrap";
import { NavigationInfo } from "../../../components/NavigationInfo";
import { PostSummary } from "./PostSummary";
import { Post } from "../reactionTypes";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import { Ratings } from "../components/Ratings";
import { useHistory } from "react-router-dom";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import { useDispatch, useSelector } from "react-redux";
import {
  changeSortCriteria,
  getSelectedTopicIds,
  getSortCriteria,
  SortCriteria,
} from "./postListPageSlice";
import { PostList } from "./PostList";
import { BackendEndpoint } from "../../../constants";
import useOnScreen from "../../../utils/useOnScreen";
import { SearchBar } from "../../../components/SearchBar";
import { post } from "../mockData/mockPost";
import AddIcon from "@mui/icons-material/Add";
import { debounce } from "@mui/material";
import { useAppSelector } from "../../../config/redux-hooks";

const PageDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

const ForumListOptions = styled.div`
  display: grid;
  grid-template-columns: auto auto 1fr;
  margin-bottom: 1%;
  justify-items: end;
  gap: 1rem;
`;

const MarginlessDropdownToggle = styled(DropdownToggle)`
  margin: 0;
  border-radius: 2rem;
  &:first-letter {
    text-transform: capitalize;
  }
`;

const DropdownFilter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

const NewPostButton = styled(Button)`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 0.25rem;
`;

interface CommentListProps {
  userId?: string;
}

function usePosts(
  sortCriteria: SortCriteria,
  bottomOfPostsLineIsVisible: boolean,
  searchBarText: string
) {
  const [posts, setPosts] = useState<Post[]>([]);
  const [completePosts, setCompletePosts] = useState<Post[]>([]);
  const [postToUserMap, setPostToUserMap] = useState<Map<Post, any>>(new Map());
  const [endOfPostsReached, setEndOfPostsReached] = useState<boolean>(false);
  const [cursor, setCursor] = useState<string>("");
  const selectedTopicIds = useAppSelector(getSelectedTopicIds);

  useEffect(() => {
    setPosts([]);
    setCompletePosts([]);
    setEndOfPostsReached(false);
    setCursor("");
  }, [sortCriteria]);

  // ON 'sortCriteria' CHANGE, refetch the posts
  useEffect(() => {
    if (bottomOfPostsLineIsVisible && !endOfPostsReached) {
      fetch(
        `${BackendEndpoint}api/v1/posts?sortCriterion=${sortCriteria}&cursor=${cursor}`,
        {
          credentials: "include",
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            // Hmmmm, this is being called twice for some reason

            if (result.data.posts.length != 0) {
              setPosts([...posts, ...result.data.posts]);
              setCompletePosts([...posts, ...result.data.posts]);
              var receivedPosts: Post[] = result.data.posts as Post[];
              receivedPosts.map((post) => {
                fetch(
                  `${BackendEndpoint}api/v1/users/${post!.reaction.authorUUID}`,
                  { credentials: "include" }
                )
                  .then((res) => res.json())
                  .then(
                    (result2) => {
                      setPostToUserMap(
                        (oldMap) => new Map(oldMap.set(post, result2.data))
                      );

                    },
                    (error) => {
                      console.error(error);
                    }
                  );
              });
              setCursor(result.data.cursor);
            } else {
              setEndOfPostsReached(true);
            }
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            console.error(error);
          }
        );
    }
  }, [bottomOfPostsLineIsVisible, endOfPostsReached, searchBarText]);

  // TODO: remove this and filter via the backend, maybe?
  // Debounced for search
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      var searchText = searchBarText.toLowerCase().trim();
      setPosts(
        completePosts
          .filter((post) =>
            selectedTopicIds.size > 0
              ? post.topics.some((topic) => selectedTopicIds.has(topic.Topicid))
              : true
          )
          .filter((post) => {
            return (
              post.title.toLowerCase().includes(searchText) || // Note that this matches the empty string
              post.topics.some((topic) =>
                topic.Name.toLowerCase().includes(searchText)
              ) ||
              post.reaction.text.toLowerCase().includes(searchText)
            );
          })
      );
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [searchBarText, selectedTopicIds, completePosts]);

  return { posts, postToUserMap };
}

function getNameForUser(userId: string): string {
  return "AngeloThinker";
}

export const PostListContent: FC<CommentListProps> = ({ userId }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const bottomOfPostsLineRef = useRef<HTMLHRElement>(null);
  const bottomOfPostsLineIsVisible = useOnScreen(bottomOfPostsLineRef);

  const sortCriteria = useSelector(getSortCriteria);
  const [searchBarText, setSearchBarText] = useState<string>("");

  const { posts, postToUserMap } = usePosts(
    sortCriteria,
    bottomOfPostsLineIsVisible,
    searchBarText
  );

  function handleSelectSortCriteria(sortCriteria: string | null) {
    dispatch(changeSortCriteria(sortCriteria! as SortCriteria));
  }

  function handleSearchText(searchBarText: string) {
    setSearchBarText(searchBarText);
  }

  return (
    <div>
      <PageDetails>
        {userId ? (
          <NavigationInfo
            title={`Posts by ${getNameForUser(userId)}`}
            backMessage="Back to Mentorship Hub"
          />
        ) : (
          <>
            <NavigationInfo title="Mentorship Hub" />
            Have a burning question? Allow one of our mentors to help!
          </>
        )}
      </PageDetails>
      <ForumListOptions>
        <SearchBar
          placeholder="Search Forum Posts"
          searchBarText={searchBarText}
          onChange={handleSearchText}
        />
        <DropdownFilter>
          Sort by:
          <Dropdown>
            <MarginlessDropdownToggle>{sortCriteria}</MarginlessDropdownToggle>
            <DropdownMenu>
              <DropdownItem eventKey="top" onSelect={handleSelectSortCriteria}>
                Top
              </DropdownItem>
              <DropdownItem eventKey="new" onSelect={handleSelectSortCriteria}>
                New
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </DropdownFilter>
        <div>
          <NewPostButton
            variant="link"
            onClick={() => {
              history.push("/mentorship/posts/new");
            }}
          >
            New Post
            <AddIcon fontSize="inherit" />
          </NewPostButton>
        </div>
      </ForumListOptions>
      <hr />
      <PostList posts={posts} postToUserMap={postToUserMap} />
      <hr ref={bottomOfPostsLineRef} />
    </div>
  );
};
