import React from "react";
import styled from "styled-components";
import { ProfilePicture } from "../../components/ProfilePicture";

const MessageListItemContainer = styled.div`
  display: grid;
  /* flex-direction: row; */
  grid-template-columns: auto minmax(0, 1fr);
  gap: 1rem;
  :hover {
    background-color: lightgray;
  }
`;

const MessageHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 20px;
`;

const PhotoHeader = styled.div`
  margin-top: 20px;
`;

const MessageContents = styled.div`
  flex-grow: 1;
  max-width: 100%;
  overflow-wrap: break-word;
`;

const SenderName = styled.span`
  font-weight: bold;
`;

interface MessageListItemProps {
  senderProfilePictureSrc: string;
  senderName: string;
  timestamp: number; // TODO: Maybe change this to a timestamp type?
  messageBody: string;
  showMetadata: boolean;
}

// Note: Doesn't contain the profile picture
export const MessageListItem = ({
  senderProfilePictureSrc,
  senderName,
  timestamp,
  messageBody,
  showMetadata,
}: MessageListItemProps) => {
  let date = new Date(timestamp/10000);
  const day = date.toLocaleString('default', { month: 'long' , day: 'numeric'});
  const time = date.toLocaleString('default', { hour: 'numeric', minute: 'numeric', hour12: true })

  return (
    <MessageListItemContainer>
      <div
        style={
          showMetadata ? undefined : { visibility: "hidden", height: "0px" }
        }
      >
        <PhotoHeader>
          <ProfilePicture size="small" src={senderProfilePictureSrc} />
        </PhotoHeader>
      </div>
      <MessageContents>
        {showMetadata && (
          <MessageHeader>
            <SenderName>{senderName}</SenderName>
            <span> {day + ", " + time}</span>
          </MessageHeader>
        )}
        <div>{messageBody}</div>
      </MessageContents>
    </MessageListItemContainer>
  );
};
