import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../../config/redux-hooks";
import { Topic } from "../Topic";
import { TopicControls } from "../TopicControls";
import {
  addSelectedTopic,
  getSelectedTopics,
  removeSelectedTopicAtIndex,
} from "./newPostPageSlice";
import { PostOptionSelector } from "./PostOptionSelector";

export const NewPostControls: FC = () => {
  const dispatch = useAppDispatch();
  const selectedTopics = useAppSelector(getSelectedTopics);

  return (
    <div>
      <TopicControls
        removeSelectedTopicAtIndex={(index: number) =>
          dispatch(removeSelectedTopicAtIndex(index))
        }
        addSelectedTopic={(topic: Topic) => dispatch(addSelectedTopic(topic))}
        selectedTopics={selectedTopics}
      />
      <PostOptionSelector />
    </div>
  );
};
