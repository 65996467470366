import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { auth } from "../../services/firebase";
import { joinConversation } from "./pubnub/joinConversationCommand";
import { ProfilePicture } from "../../components/ProfilePicture";
import { getMostRecentMessage } from "./currentConversationModel";
import { fetchMessageHistory } from "pubnub-redux";

const ChatListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding-left: 1rem;
  :hover {
    background-color: lightgray;
    cursor: pointer;
  }
`;


const ChatListUserName = styled.div`
  padding-top: 0px;
`;

interface ChatListItemProps {
  displayTitle: string;
  convoId: string;
}


export const ChatListItem = ({
  displayTitle,
  convoId,
}: ChatListItemProps) => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchMessageHistory({
        channel: convoId,
        includeMeta: true,
      })
    );
  }, [convoId]);

  const recentMessages = useSelector(getMostRecentMessage(convoId))
  const isNewMessage = recentMessages.length > 0
  const mostRecentMessage = isNewMessage
    ? recentMessages[0]?.message.body : 'Say hello :)'

  const lengthAdjustedMessage = mostRecentMessage.length > 25 ?
    mostRecentMessage.slice(0, 25) + '...' : mostRecentMessage

  return (
    <ChatListItemContainer
      key={convoId}
      onClick={() => {
        dispatch(
          joinConversation(auth.currentUser!.uid, convoId)
        );
      }}
    >
      <ProfilePicture
        size="small"
        src="https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png"
      />
      <ChatListUserName>
        <div>{displayTitle}</div>
        <div>{lengthAdjustedMessage}</div>
      </ChatListUserName>
    </ChatListItemContainer>
  );
};
