import { InstitutionInfo } from "../Institution";

export function getUniversityOfWaterloo(): InstitutionInfo {
  return { 
        institutionId: 1,
        name: "University of Waterloo",
        isHighSchool: false,
        logoImage: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Mike-and-Ophelia-Lazaridis-Quantum-Nano-Centre.png",
        institudeDescription: "The University of Waterloo is a public research university with a main campus in Waterloo, Ontario, Canada. The main campus is on 404 hectares of land adjacent to \"Uptown\" Waterloo and Waterloo Park.",
        moreInfoLink: "https://uwaterloo.ca/",
        instituteAddress: "200 University Ave W, Waterloo, ON N2L 3G1",
        institutePhone: "(519) 888-4567",
        admissionsQuickLink: "https://uwaterloo.ca/future-students/admissions",
        undergraduateQuickLink: "https://uwaterloo.ca/future-students/programs/by-faculty", 
        tutionQuickLink: "https://uwaterloo.ca/finance/student-financial-services/tuition-fee-schedules/fee-schedule-graduate-students-winter-2022",
        housingQuickLink: "https://uwaterloo.ca/campus-housing/",
        campusQuickLink: "https://uwaterloo.ca/coronavirus/return/campus-buildings",
        coopcareerQuickLink: "https://uwaterloo.ca/future-students/co-op",
        graduateQuickLink: "https://uwaterloo.ca/graduate-studies-postdoctoral-affairs/future-students/programs",
        faqQuickLink: "https://uwaterloo.ca/future-students/admissions/faqs-admissions"
      }
  ;
}