import React, { useEffect, useState } from "react";
import { Button as BootstrapButton, Dropdown, Media } from "react-bootstrap";
import styled from "styled-components";
import { GenericChip } from "../../../components/GenericChip";
import { ProfilePicture } from "../../../components/ProfilePicture";
import { DefaultTopicChip } from "../../../components/TopicChips";
import { CommentList } from "./CommentList";
import { post, postComments } from "../mockData/mockPost";
import {
  Ratings,
  useReactionVoteValue,
  voteOnReaction,
} from "../components/Ratings";
import { Comment, Post } from "../reactionTypes";
import ReactMarkdown from "react-markdown";
import {
  changeCommentSortCriteria as changeCommentSortCriterion,
  CommentSortCriterion,
  getCommentSortCriteria,
  setComments,
} from "./postPageSlice";
import { useDispatch, useSelector } from "react-redux";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import { NavigationInfo } from "../../../components/NavigationInfo";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import IconButton from "@mui/material/IconButton";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import ReactMde from "react-mde";
import { ReactionEditor } from "../ReactionEditor";
import { Comment as CommentIcon } from "@mui/icons-material";
import { auth } from "../../../services/firebase";
import { BackendEndpoint } from "../../../constants";
import { useHistory } from "react-router-dom";
import { formatDistance } from "date-fns";

const PostLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const UpperPost = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const PostMetadata = styled.div`
  color: #9ea3a9;
`;

const PostTitle = styled.span`
  /* margin-right: 1%; */
`;

const PostInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 1em;
  /* width: 100%; */
`;

const PaddedReactMarkdown = styled(ReactMarkdown)`
  padding-left: 1rem;
  padding-right: 1rem;
`;

const PostCommentControls = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
`;

const PostHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 1em;
`;

const PostReactions = styled.div`
  flex-grow: 1; // Allow the reactions to take up the rest of the line

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 1em;
`;

const DropdownFilter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

const MarginlessDropdownToggle = styled(DropdownToggle)`
  margin: 0;
  border-radius: 2rem;
  &:first-letter {
    text-transform: capitalize;
  }
`;

const ReplyArea = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */

  margin-top: 1rem;
  margin-bottom: 2rem;
`;

const Button = styled(BootstrapButton)`
  margin-top: 0;
`;

interface PostContentsProps {
  post: Post;
  comments: Comment[];
  user: any;
}

export const PostContents = ({ comments, post, user }: PostContentsProps) => {
  const [newCommentText, setNewCommentText] = useState<string>("");

  const dispatch = useDispatch();
  const history = useHistory();

  function createComment(parentid: number, newCommentText: string) {
    const timestamp = new Date().toISOString(); // TODO: Change this to a better time library
    const currentUserFirebaseUID = auth.currentUser!.uid; // Assumption: only called when the user is logged in

    // window.location.reload();
    fetch(`${BackendEndpoint}api/v1/comments`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        authorUUID: currentUserFirebaseUID,
        parentid,
        text: newCommentText,
        timestamp,
      }),
      credentials: "include",
    })
      .then(() => {
        setNewCommentText("");
        history.go(0);

      })
      .catch((e) => {
        console.error(e);
      });
  }

  useEffect(() => {
    dispatch(setComments(comments!));
  }, [comments]);

  const sortCriteria = useSelector(getCommentSortCriteria);

  const { voteValue, setOrUndoVote } = useReactionVoteValue(post?.reaction);

  function handleSelectCommentSortCriterion(sortCriteria: string | null) {
    dispatch(changeCommentSortCriterion(sortCriteria! as CommentSortCriterion));
  }

  const postdateDistance = formatDistance(
    new Date(post.reaction.postdate),
    Date.now(),
    {
      addSuffix: true,
    }
  );

  // If the post's author isn't loaded yet, show "..."
  const postMetadata = user
    ? `Posted ${postdateDistance} by ${user.FirstName} ${user.LastName} (${user.Username})`
    : "...";

  return (
    <div>
      <PostLayout>
        <NavigationInfo backMessage="Back to Mentorship Hub" />
        <UpperPost>
          <PostMetadata>{postMetadata}</PostMetadata>
          <PostCommentControls>
            <IconButton>
              <FolderSpecialIcon />
            </IconButton>
            <IconButton>
              <MoreHoriz />
            </IconButton>
          </PostCommentControls>
        </UpperPost>
        <PostHeader>
          <PostTitle>{post!.title}</PostTitle>
          <PostInfo>
            <Ratings
              downvotes={post!.reaction.downvotes}
              onVote={
                auth.currentUser
                  ? (voteKind) => {
                    voteOnReaction(voteKind, post!.reaction.reactionid);
                    setOrUndoVote(voteKind);
                  }
                  : undefined
              }
              upvotes={post!.reaction.upvotes}
              voteValue={voteValue}
            />
            <ProfilePicture size="small" src={user?.ProfilePicture.String} />
            <PostReactions>
              {post!.topics.map((topic) => (
                <DefaultTopicChip key={topic.Topicid} color={topic.Color}>
                  {topic.Name}
                </DefaultTopicChip>
              ))}
            </PostReactions>
          </PostInfo>
        </PostHeader>
        <PaddedReactMarkdown>{post!.reaction.text}</PaddedReactMarkdown>
        <DropdownFilter>
          Sort by:
          <Dropdown>
            <MarginlessDropdownToggle>{sortCriteria}</MarginlessDropdownToggle>
            <DropdownMenu>
              <DropdownItem
                eventKey="top"
                onSelect={handleSelectCommentSortCriterion}
              >
                Top
              </DropdownItem>
              <DropdownItem
                eventKey="new"
                onSelect={handleSelectCommentSortCriterion}
              >
                New
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </DropdownFilter>
      </PostLayout>
      <hr />
      <ReplyArea>
        <ReactionEditor
          text={newCommentText}
          onChange={setNewCommentText}
          childProps={{
            textArea: { placeholder: "Enter your comment here" },
          }}
        />
        <Button
          disabled={newCommentText === ""}
          variant="secondary"
          onClick={() => {
            createComment(post!.reaction.reactionid, newCommentText);
          }}
        >
          Submit
        </Button>
      </ReplyArea>
      <CommentList
        id="root-comment-list"
        parentId={post!.reaction.reactionid}
      />
    </div>
  );
};
