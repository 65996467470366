import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import React from "react";
import { Image } from "react-bootstrap";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FullscreenCard } from "./FullscreenCard";
import { getInstitution } from "./getInstitutions";
import { InformationEntry } from "./InformationEntry";
import { InstitutionInfo } from "./Institution";
import { QuickLink } from "./QuickLink";
import admissionsEmoji from "../../assets/admissions.svg";
import campusEmoji from "../../assets/campus.svg";
import degreeEmoji from "../../assets/degree.svg";
import housingEmoji from "../../assets/housing.svg";
import jobsEmoji from "../../assets/jobs.svg";
import faqEmoji from "../../assets/FAQ.svg";
import tutionEmoji from "../../assets/tution.svg";

const ImageDiv = styled.div`
  flex-shrink: 0;
  border-color: grey;
  overflow: hidden;
  height: 10em;
  width: 15em;
  margin-right: 1em;
`;

const InstitutionHeader = styled.div`
  display: flex;
  flex-direction: row;
`;

const HeaderMainContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1em;
`;

const InstitutionDescription = styled.div`
  flex-grow: 1;
  flex-basis: 0px;
`;

const InformationEntries = styled.div``;

const QuickLinks = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.5em;

  margin-left: 1em;
  margin-bottom: 1.5em;
`;

const QuestionAndAnswers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-left: 1em;
  margin-top: 1.5em;
  height:100em;
`;

const Question = styled.div`
  /* text-shadow: 0px 0.4px, 0.4px 0px, 0.4px 0px; */
  font-weight: bold;
  margin-bottom: 1em;
`;

const Answer = styled.p`
  font-weight: lighter;
  white-space: pre-line;
  width: 100%;
`;

function getInfoForInstitution(institutionId: number) {
  return getInstitution(institutionId);
}

// TODO: Add icons and emojis, and user counts as per mock-up
export const InstitutionInfoPage = () => {
  const { institutionId } = useParams<{ institutionId: string | undefined }>();


  // institutionId is never null because this page can't be accessed without a parameter
  const institutionInfo: InstitutionInfo = getInfoForInstitution(Number(institutionId!));

  // TODO: NEED TO PRINT 404 PAGE for {} institution 
  return (
    <FullscreenCard>
      <InstitutionHeader>
        <ImageDiv>
          <Image
            fluid
            src={institutionInfo.logoImage}
          />
        </ImageDiv>
        <div>
          <Link to="/infohub/schools">
            <ArrowBackIos fontSize="inherit" />
            Back to School Search
          </Link>
          <h1>
            {institutionInfo.name}
          </h1>
          <HeaderMainContent>
            <InstitutionDescription>
              {institutionInfo.institudeDescription}
              <div>
                <a href={institutionInfo.moreInfoLink}>More Info...</a>
              </div>
            </InstitutionDescription>
            <InformationEntries>
              <InformationEntry
                entryName="Address"
                value={institutionInfo.instituteAddress}
              />
              <InformationEntry entryName="Phone" value={institutionInfo.institutePhone} />
            </InformationEntries>
          </HeaderMainContent>
        </div>
      </InstitutionHeader>
      <hr />
      <h1>Quick Links</h1>
      <QuickLinks>
        <QuickLink
          src={institutionInfo.admissionsQuickLink}
          name="Admissions"
          image={admissionsEmoji}
        />
        &emsp;
        <QuickLink
          src={institutionInfo.undergraduateQuickLink}
          name="Undergraduate"
          image={degreeEmoji}
        />
        &emsp;
        <QuickLink
          src={institutionInfo.tutionQuickLink}
          name="Tuition"
          image={tutionEmoji}
        />
        &emsp;
        <QuickLink
          src={institutionInfo.housingQuickLink}
          name="Housing"
          image={housingEmoji}
        />
      </QuickLinks>
      <QuickLinks>
        <QuickLink
          src={institutionInfo.campusQuickLink}
          name="Campus"
          image={campusEmoji}
        />
        &emsp;
        <QuickLink
          src={institutionInfo.coopcareerQuickLink}
          name="Co-op and careers"
          image={jobsEmoji}
        />
        &emsp;
        <QuickLink
          src={institutionInfo.graduateQuickLink}
          name="Graduate"
          image={degreeEmoji}
        />
      </QuickLinks>
      <h1>Frequently Asked Questions</h1>
      <QuestionAndAnswers>
        {/* <iframe src={institutionInfo.faqQuickLink} title="Frequently Asked Questions"></iframe> */}
        {/* <embed type="text/html" src={institutionInfo.faqQuickLink}></embed> */}
        <div>
          <QuickLink
            src={institutionInfo.faqQuickLink}
            name="FAQ"
            image={faqEmoji}
          />
        </div>
      </QuestionAndAnswers>
    </FullscreenCard>
  );
};
