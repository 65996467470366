import React from "react";
import styled from "styled-components";

const EntryName = styled.span`
  font-weight: bold;
`;

interface InformationEntryProps {
  entryName: string;
  value: string;
}

export const InformationEntry = ({
  entryName,
  value,
}: InformationEntryProps) => {
  return (
    <div>
      <EntryName>{entryName}: </EntryName>
      {value}
    </div>
  );
};
