import {
  createChannelDataReducer,
  createChannelMembersReducer,
  createChannelsListReducer,
  createNetworkStatusReducer,
} from "pubnub-redux";
// import { currentConversationStateReducer, getCurrentConversationId }  from "./currentConversationModel.js"

import { combineReducers } from "redux";
import { createSelector } from "reselect";
import { AuthenticationStateReducer } from "../../authentication/authenticationModel.js";
import { JoinedConversationsStateReducer } from "../joinedConversationModel";
import { getMessagesById, MessageStateReducer } from "../messagesModel";
import { getUsersById, UsersReducer } from "../usersModel";
import { UsersListReducer } from "../usersListModel";

// TODO: May want to refactor this file into separate files that deal with each reducer/and associated data/selectors

/**
 * create a reducer which holds all known conversation objects in a normalized form
 */
const conversationStateReducer = combineReducers({
  conversations: createChannelDataReducer(),
  allConversations: createChannelsListReducer(),
});

const ConversationMembersStateReducer = createChannelMembersReducer();

/**
 * Create a reducer to hold the current online status of the user
 */
const NetworkStatusReducer = createNetworkStatusReducer(false);

// ===================TODO: MOVE TO A NEW FILE, but importing isn't working

export const FOCUS_ON_CONVERSATION = "FOCUS_ON_CONVERSATION";

export const focusOnConversation = (target) => {
  return {
    type: FOCUS_ON_CONVERSATION,
    payload: target,
  };
};

const initialState = {
  currentConversationId: "",
};

export const currentConversationStateReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FOCUS_ON_CONVERSATION:
      return { ...state, currentConversationId: action.payload };
    default:
      return state;
  }
};

const getCurrentConversationSlice = (state) => state.currentConversation;

export const getCurrentConversationId = createSelector(
  [getCurrentConversationSlice],
  (currentConversation) => {
    return currentConversation.currentConversationId;
  }
);

/**
 * Create a selector that that returns the list of messages in the currentConversation joined
 * to the user that sent that message
 *
 * TODO:
 * This implementation will cause the dependant component to re-render if any user data has changed
 * if the current conversation has changed or if any message has changed or if any user has changed.
 * This needs to be reduced in scope
 *
 * TODO: This needs to sort by time token; object keys are not guarenteed to retain order in js.
 */
export const getCurrentConversationMessages = createSelector(
  [getMessagesById, getCurrentConversationId, getUsersById],
  (messages, conversationId, users) => {
    return messages[conversationId]
      ? Object.values(messages[conversationId])
          .filter((message) => message.channel === conversationId)
          .map((message) => {
            // if the user is unknown queue up a request for the missing data
            return {
              ...message,
              timetoken: String(message.timetoken),
              sender: users[message.message.senderId]
                ? {
                    id: message.message.senderId,
                    name: message.message.senderId,
                  }
                : {
                    id: "unknown",
                    name: "unknown",
                  },
            };
          })
          .sort((messageA, messageB) => {
            if (messageA.timetoken === messageB.timetoken) {
              return 0;
            } else if (messageA.timetoken > messageB.timetoken) {
              return 1;
            } else {
              return -1;
            }
          })
      : [];
  }
);

//======================================================================

/**
 * Combine all of the PubNub reducers in this application (NOTE: NOT ALL OF THE PN REDUCERS THAT EXIST ARE INCLUDED)
 */
const pubNubReducers = {
  authentication: AuthenticationStateReducer,
  users: UsersReducer,
  usersList: UsersListReducer,
  conversations: conversationStateReducer,
  conversationMembers: ConversationMembersStateReducer,
  currentConversation: currentConversationStateReducer,
  joinedConversations: JoinedConversationsStateReducer,
  messages: MessageStateReducer,
  networkStatus: NetworkStatusReducer,
};

export { pubNubReducers };
