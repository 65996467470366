import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { NavigationInfo } from "../../components/NavigationInfo";
import { BackendEndpoint } from "../../constants";
import { auth } from "../../services/firebase";
import { ChatContents } from "./ChatContents";
import { ChatListItem } from "./ChatListItem";
import {
  focusOnConversation,
  getCurrentConversationId,
  getCurrentConversationMessages,
} from "./currentConversationModel";
import {
  useConversationsForUser,
} from "./joinedConversationModel";
import { joinConversation } from "./pubnub/joinConversationCommand";
import { Link } from "react-router-dom";
import { SearchBar } from "../../components/SearchBar";

const ChatPageContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  padding: 1.5rem 1.5rem 1.5rem 0rem;
  gap: 1rem;
`;

const Sidebar = styled.div`
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  overflow: hidden; // So that child elements can have scrollbar(s)

  & > * {
    min-width: 0; // So that the children don't grow wider than the sidebar
  }

  padding-right: 1rem;
  border-right: 1px solid rgba(0, 0, 0, 0.1); // Match other divider lines in the app
`;

const SidebarHeader = styled.div`
  margin-left: 1rem;
  margin-bottom: 1rem;
`;

const ChatList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: auto;
`;

const ChatListEmptyMessage = styled.div`
  padding-left: 1rem;
  text-align: center;
  height: 100%;
`;

const userURL = `${BackendEndpoint}api/v1/users/`;

function fetchUser(uuid: string) {
  return fetch(userURL + uuid, {
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => {
      return res.data;
    });
}

function getTargetId(conversationId: string, isSender: boolean) {
  var uuid1: string = conversationId.split('&')[0];
  var uuid2: string = conversationId.split('&')[1];

  if (!uuid1 || !uuid2) {

    return "";
  }

  var target: string = (auth.currentUser!.uid === uuid1) ? uuid2 : uuid1;
  var sender: string = (auth.currentUser!.uid === uuid1) ? uuid1 : uuid2;
  return (isSender) ? sender : target;
}

function getUserName(conversationId: string, isSender: boolean) {
  var uuid = getTargetId(conversationId, isSender);
  return fetchUser(uuid).then(user => {
    if (!user) {
      return ""
    }
    return user.FirstName + " " + user.LastName;
  });
}

export const ChatPage = () => {
  const joinedConversations = useConversationsForUser(auth.currentUser!.uid);
  const userHasJoinedConversations = joinedConversations.length > 0;
  const [currConvoUsers, setCurrConvoUsers] = useState(joinedConversations.map(joinConversation => ""))
  const [convoTitle, setConvoTitle] = useState("To ...");
  const [senderTitle, setSenderTitle] = useState("From ...");
  const [chatSearchBarText, setChatSearchBarText] = useState("");

  const dispatch = useDispatch();
  const currConvoMsgs = useSelector(getCurrentConversationMessages);
  const currConvoId = useSelector(getCurrentConversationId);

  function handleSearchText(searchBarText: string) {
    setChatSearchBarText(searchBarText)
  }

  useEffect(() => {
    // If there isn't already a current conversation
    if (currConvoId === "" && userHasJoinedConversations) {
      var length = joinedConversations.length
      for (let i = 0; i < length; i++) {
        var sender = getTargetId(joinedConversations[i].id, true)
        var target = getTargetId(joinedConversations[i].id, false)
        if (sender.trim() !== "" || target.trim() !== "") {
          dispatch(joinConversation(auth.currentUser!.uid, joinedConversations[i].id));
          break;
        }
      }
    }
  }, [currConvoId, joinedConversations]);

  useEffect(() => {
    Promise.allSettled(joinedConversations.map(joinConversation => getUserName(joinConversation.id, false)))
      .then(results => results.filter(result => result.status === "fulfilled").map(result => (result as PromiseFulfilledResult<string>).value))
      .then(results => setCurrConvoUsers(results))

    // fetch the username/user info for the person being talked to
    getUserName(currConvoId, false)
      .then(name => setConvoTitle(name))
    // fetch the username/user info for the sender
    getUserName(currConvoId, true)
      .then(name => setSenderTitle(name))
  }, [currConvoId])

  return (
    <ChatPageContainer>
      <Sidebar>
        <SidebarHeader>
          <NavigationInfo backMessage="Go Back" title="Chat" />
          <SearchBar onChange={handleSearchText} placeholder="Search users" searchBarText={chatSearchBarText} />
        </SidebarHeader>
        <ChatList>
          {userHasJoinedConversations ? (
            joinedConversations.map((joinedConversation, index) => (
              <ChatListItem
                key={joinedConversation.id}
                convoId={joinedConversation.id}
                displayTitle={currConvoUsers[index]}
              />
            ))
          ) : (
            <ChatListEmptyMessage>
              <div>
                <div>You haven't joined any conversations;</div>
                <Link to="/search">search for people to chat with!</Link>
              </div>
            </ChatListEmptyMessage>
          )}
        </ChatList>
      </Sidebar>
      <ChatContents
        currConvoMsgs={currConvoMsgs}
        displayTitle={convoTitle}
        currUserName={senderTitle}
      />
    </ChatPageContainer>
  );
};
