import { auth } from "../../../services/firebase";
import { AppThunk } from "../../../config/store";
import { BackendEndpoint } from "../../../constants";
import { getNewPostPageSlice, resetNewPostState } from "./newPostPageSlice";

function createPostCreationThunk(isDraft: boolean): AppThunk {
  return async (dispatch, getState) => {
    // API call to post a post goes here; print the new post data for now
    // Remember to have "isDraft" set as false
    const newPostPageSlice = getNewPostPageSlice(getState());

    const todayDate = new Date(); // TODO: Change this to a better time library
    const currentUserFirebaseUID = auth.currentUser!.uid; // Assumption: only called when the user is logged in
    fetch(`${BackendEndpoint}api/v1/posts`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        authorUUID: currentUserFirebaseUID, // TODO: This ID may need to change
        isDraft,
        postSettings: {
          audienceOptions: newPostPageSlice.audienceOptions,
        },
        postTimestamp: todayDate.toISOString(),
        selectedTopics: newPostPageSlice.selectedTopics,
        subscribedToByAuthor: newPostPageSlice.subscribedToByAuthor,
        text: newPostPageSlice.text,
        title: newPostPageSlice.title,
      }),
      credentials: "include",
    })
      .then(() => {
        dispatch(resetNewPostState());
      })
      .catch((e) => {
        console.error(e);
      });
  };
}

export function postNewPost(): AppThunk {
  return createPostCreationThunk(false); // isDraft = false
}

export function savePostAsDraft(): AppThunk {
  return createPostCreationThunk(true); // isDraft = true
}
