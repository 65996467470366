import React, { FC, useState } from "react";
import styled from "styled-components";
import ReactMde from "react-mde";
import ReactMarkdown from "react-markdown";
import "react-mde/lib/styles/css/react-mde-all.css";
import { Button, Form } from "react-bootstrap";
import { NavigationInfo } from "../../../components/NavigationInfo";
import {
  getSubscribedToByAuthor,
  getText,
  getTitle,
  setText,
  setTitle,
  toggleSubscribedToByAuthor,
} from "./newPostPageSlice";
import { postNewPost, savePostAsDraft } from "./postCreationThunks";
import { useAppDispatch, useAppSelector } from "../../../config/redux-hooks";

function generateMarkdownPreview(markdown: string) {
  return Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>);
}

const UpperPostOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 1%;
  margin-bottom: 1%;
`;

const MarginlessButton = styled(Button)`
  margin: 0px;
  margin-left: 1%;
`;

const TitleInput = styled(Form.Control)`
  flex: 1;
`;

export const NewPostContent: FC = () => {
  const dispatch = useAppDispatch();

  const title = useAppSelector(getTitle);
  const text = useAppSelector(getText);
  const subscribedToByAuthor = useAppSelector(getSubscribedToByAuthor);

  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");

  const saveAndPostAreDisabled = title === "";

  return (
    <div>
      <NavigationInfo backMessage="Back to Mentorship Hub" title="New Post" />
      <UpperPostOptions>
        <TitleInput
          placeholder="Title"
          onChange={(changeEvent: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(setTitle(changeEvent.target.value));
          }}
          value={title}
        />
        <MarginlessButton
          variant="outline-dark"
          disabled={saveAndPostAreDisabled}
          onClick={() => {
            dispatch(savePostAsDraft());
          }}
        >
          Save For Later
        </MarginlessButton>
        <MarginlessButton
          variant="outline-dark"
          disabled={saveAndPostAreDisabled}
          onClick={() => {
            dispatch(postNewPost());
          }}
        >
          Post
        </MarginlessButton>
      </UpperPostOptions>
      <ReactMde
        value={text}
        onChange={(text) => {
          dispatch(setText(text));
        }}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        generateMarkdownPreview={generateMarkdownPreview}
        childProps={{ textArea: { placeholder: "Enter your post here" } }}
      />
      <hr />
      <Form.Check
        id="subscribedToByAuthorCheckbox"
        label="Send a notification whenever this post is replied to"
        checked={subscribedToByAuthor}
        onChange={() => {
          dispatch(toggleSubscribedToByAuthor());
        }}
      />
    </div>
  );
};
