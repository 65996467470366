import { InstitutionInfo } from "../Institution";

export function getUniversityOfBritishColumbia(): InstitutionInfo {
  return { 
    institutionId: 3,
    name: "University of Bristish Columbia",
    isHighSchool: false,
    logoImage: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b1/University_of_British_Columbia%2C_Rose_Garden.jpg/640px-University_of_British_Columbia%2C_Rose_Garden.jpg",
    institudeDescription: "The University of British Columbia is a public research university with campuses near Vancouver and in Kelowna, British Columbia. Established in 1908, UBC is British Columbia's oldest university.",
    moreInfoLink: "https://www.ubc.ca/about/",
    instituteAddress: "Vancouver, BC V6T 1Z4",
    institutePhone: "(604) 822-2211",
    admissionsQuickLink: "https://www.ubc.ca/admissions/",
    undergraduateQuickLink: "https://you.ubc.ca/programs/#mode=by-topic&viewMode=list", 
    tutionQuickLink: "https://students.ubc.ca/enrolment/finances/tuition-fees",
    housingQuickLink: "https://vancouver.housing.ubc.ca/",
    campusQuickLink: "https://www.ubc.ca/our-campuses/",
    coopcareerQuickLink: "https://coop.ubc.ca/students/why-co-op",
    graduateQuickLink: "https://www.grad.ubc.ca/prospective-students/graduate-degree-programs",
    faqQuickLink: "https://you.ubc.ca/applying-ubc/blog/after-you-apply/faqs-canadian-applicants/"
  }
  ;
}