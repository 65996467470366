import AddCircleIcon from "@mui/icons-material/AddCircle";
import SendIcon from "@mui/icons-material/Send";
import IconButton from "@mui/material/IconButton";
import React, {
  ChangeEventHandler,
  KeyboardEventHandler,
  useState,
  useEffect, 
  useRef,
} from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { NavigationInfo } from "../../components/NavigationInfo";
import { ProfilePicture } from "../../components/ProfilePicture";
import { BackendEndpoint } from "../../constants";
import { auth } from "../../services/firebase";
import { getCurrentConversationMessages } from "./currentConversationModel";
import { useConversationsForUser } from "./joinedConversationModel";
import { MessageListItem } from "./MessageListItem";
import { CustomMessage } from "./messagesModel";
import { sendTextMessage } from "./pubnub/sendMessage";

const ChatContentsContainer = styled.div`
  /* display: grid;
  flex-direction: column;
  width: 100%;
  height: 100%; */

  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  gap: 1rem;
  overflow: hidden; // Prevents children from causing this container to grow
`;

const ConversationTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

const ChatArea = styled.div`
  display: grid;
  grid-template-rows: minmax(0, 1fr) auto;
  border-radius: 1rem;
  border: 1px solid #ced4da;
  width: 100%;
  padding: 1rem;
`;

const MessageViewport = styled.div`
  overflow-y: auto;
`;

const InputBox = styled.div`
  display: grid;
  grid-template-columns: 2rem 1fr 2rem;
  grid-template-rows: 2rem;
  gap: 1rem;

  border-radius: 2rem;
  border: 1px solid #ced4da;
  padding: 0.5rem 1rem 0.5rem 1rem;
`;

const MessageInput = styled.input`
  // The border and outline are disabled here because there are already some outside this component
  border: none;
  :focus {
    outline: none;
  }
`;

type User = any;

interface ChatContentsProps {
  currConvoMsgs: CustomMessage[];
  displayTitle: string;
  currUserName: string;
}

export const ChatContents = ({
  currConvoMsgs,
  displayTitle,
  currUserName,
}: ChatContentsProps) => {
  const dispatch = useDispatch();

  const [inputMessage, setInputMessage] = useState<string>("");
  const handleInputMessageChange: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setInputMessage(event.currentTarget.value);
  };

  const inputMessageSendIsDisabled = inputMessage === "";

  function handleInputMessageSend() {
    if (inputMessage.trim() !== "") {
      dispatch(sendTextMessage(inputMessage));
    }
    // Clear the input message
    setInputMessage("");
  }

  const handleInputEnterKeyPress: KeyboardEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (event.key === "Enter") {
      handleInputMessageSend();
    }
  };

  const messagesEndRef = useRef<null | HTMLDivElement>(null); 

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }
  
  useEffect(() => {
    scrollToBottom()
  }, [currConvoMsgs]);

  return (
    <ChatContentsContainer>
      <ConversationTitle>
        <ProfilePicture
          size="small"
          src="https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png"
        />
        {displayTitle}
      </ConversationTitle>
      <ChatArea>
        <MessageViewport>
          {currConvoMsgs.map(
            ({ message: { senderId, body }, timetoken }, i, currConvoMsgs) => (
              i == 0 
              || body.trim() !== currConvoMsgs[i-1].message.body.trim() ? 
              (
                <MessageListItem
                  key={i}
                  senderProfilePictureSrc="https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png"
                  senderName={auth.currentUser!.uid == senderId ? currUserName : displayTitle}
                  showMetadata={
                    i === 0 || currConvoMsgs[i - 1].message.senderId !== senderId
                  }
                  // I'm not 100% certain we can null assert timetoken here
                  timestamp={parseInt(timetoken!.toString())}
                  messageBody={body}
                /> 
              ) : 
              (
                <div></div>
              )
            )
          )}
          <div ref={messagesEndRef} />
        </MessageViewport>
        <InputBox>
          <IconButton color="primary">
            <AddCircleIcon />
          </IconButton>
          <MessageInput
            key={'input'}
            placeholder={`Message ${displayTitle}`}
            value={inputMessage}
            onChange={handleInputMessageChange}
            onKeyPress={handleInputEnterKeyPress}
          />
          <IconButton
            color="primary"
            disabled={inputMessageSendIsDisabled}
            onClick={handleInputMessageSend}
          >
            <SendIcon />
          </IconButton>
        </InputBox>
      </ChatArea>
    </ChatContentsContainer>
  );
};
