import { InstitutionInfo } from "../Institution";

export function getUniversityOfToronto(): InstitutionInfo {
  return { 
    institutionId: 2,
    name: "University of Toronto",
    isHighSchool: false,
    logoImage: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2a/University_College_Lawn%2C_University_of_Toronto%2C_Canada.jpg/640px-University_College_Lawn%2C_University_of_Toronto%2C_Canada.jpg",
    institudeDescription: "The University of Toronto (U of T or UToronto), known more specifically as the University of Toronto St. George,offers over 700 undergraduate and 200 graduate programs.",
    moreInfoLink: "https://www.utoronto.ca/future-students",
    instituteAddress: "27 King's College Cir, Toronto, ON M5S 1A1",
    institutePhone: "(416) 978-2011",
    admissionsQuickLink: "https://future.utoronto.ca/apply/",
    undergraduateQuickLink: "https://future.utoronto.ca/academics/undergraduate-programs/", 
    tutionQuickLink: "https://future.utoronto.ca/finances/fees/",
    housingQuickLink: "https://studentlife.utoronto.ca/task_levels/find-a-place-to-live-on-or-off-campus/",
    campusQuickLink: "https://www.utoronto.ca/university-life/campuse",
    coopcareerQuickLink: "https://future.utoronto.ca/academics/beyond-classes-global-co-op-experiential/",
    graduateQuickLink: "https://www.sgs.utoronto.ca/programs/",
    faqQuickLink: "https://www.utoronto.ca/utogether/faqs"
  }
  ;
}