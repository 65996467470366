import React, { FC } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import { PrivateRoute } from "../../App";
import { NewPostPage } from "./newPost/NewPostPage";
import { PostPage } from "./post/PostPage";
import { HistoryPage } from "./postList/HistoryPage";
import { PostListPage } from "./postList/PostListPage";

export const ForumRoutes = ({ authenticated }: { authenticated: boolean }) => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Redirect to={`${path}/posts`} />
      </Route>
      <Route exact path={`${path}/posts`} component={PostListPage} />
      <PrivateRoute
        authenticated={authenticated}
        exact
        path={`${path}/posts/new`}
        component={NewPostPage}
      />
      <Route exact path={`${path}/posts/:id`} component={PostPage} />
      <Route exact path={`${path}/history/:id`} component={HistoryPage} />
      {/* Handle invalid paths for this route below: */}
      <Route>Sorry, we can't seem to find that page! [404]</Route>
    </Switch>
  );
};
