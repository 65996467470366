import React, { FC } from "react";
import { ForumLayout } from "../ForumLayout";
import "react-mde/lib/styles/css/react-mde-all.css";
import { PostListContent } from "./PostListContent";
import { PostListControls } from "./PostListControls";
import { useParams } from "react-router";
import { post } from "../mockData/mockPost";
import { post1 } from "../mockData/mockPostList";
import { post2 } from "../mockData/mockPostList";
import { post3 } from "../mockData/mockPostList";

export const HistoryPage: FC = () => {
  const { id } = useParams<{ id: string | undefined }>();
  return (
    <ForumLayout
      contentComponent={<PostListContent userId={id} />}
      controlsComponent={<PostListControls />}
    />
  );
};
