import React from "react";
import { Button as BootstrapButton } from "react-bootstrap";
import { useHistory } from "react-router";
import styled from "styled-components";
import { FullscreenCard } from "./FullscreenCard";
import connectedWorldPicture from "../../assets/connectedWorld.svg";

const Button = styled(BootstrapButton)`
  margin-top: 0;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  height: 100%;
`;

const Divider = styled.hr`
  width: 50%;
`;

const Description = styled.p`
  margin: 0;
`;

const TellMeMore = styled.p`
  font-style: italic;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const Abc = styled.div`
  flex: 1 0 0px;
  white-space: nowrap;
`;

export const InfoSelectionPage = () => {
  const history = useHistory();

  return (
    <FullscreenCard>
      <CardContent
        style={{
          backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)), url(${connectedWorldPicture})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "55%",
          backgroundPosition: "center",
        }}
      >
        <h1>Information Hub</h1>
        <Description>
          Learn about applying to Colleges and Universities,
          <br /> and what each institution has to offer
        </Description>
        <Divider />
        <TellMeMore>Tell me more about...</TellMeMore>
        <ButtonContainer>
          <Abc>
            <Button variant="secondary" disabled>
              Applying to School
            </Button>
            <p>Coming soon!</p>
          </Abc>
          <Abc>
            <Button
              onClick={() => {
                history.push("/infohub/schools");
              }}
            >
              A specific College/University
            </Button>
          </Abc>
        </ButtonContainer>
      </CardContent>
    </FullscreenCard>
  );
};
