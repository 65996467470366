export class Topic {
  readonly Topicid: number;
  readonly Name: string;
  readonly Color: string;
  constructor(topicId: number, name: string, color: string) {
    this.Topicid = topicId;
    this.Name = name;
    this.Color = color;
  }
}

export function formatTopicName(name: string) {
  return name[0].toUpperCase() + name.substring(1);
}
