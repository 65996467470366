import { createSelector } from "reselect";
import { getUsersById } from "./usersModel";
import { getMessagesById } from "./messagesModel";

export const FOCUS_ON_CONVERSATION = "FOCUS_ON_CONVERSATION";

export const focusOnConversation = (target) => {
  return {
    type: FOCUS_ON_CONVERSATION,
    payload: target,
  };
};

const initialState = {
  currentConversationId: "",
};

export const currentConversationStateReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FOCUS_ON_CONVERSATION:
      return { ...state, currentConversationId: action.payload };
    default:
      return state;
  }
};

const getCurrentConversationSlice = (state) => {
  if(typeof state !== 'undefined'){
    return state.currentConversation;
  }
}

export const getCurrentConversationId = createSelector(
  [getCurrentConversationSlice],
  (currentConversation) => {
    if(typeof currentConversation !== 'undefined'){
      return currentConversation.currentConversationId;
    }
  }
);

/**
 * Create a selector that that returns the list of messages in the currentConversation joined
 * to the user that sent that message
 *
 * TODO:
 * This implementation will cause the dependant component to re-render if any user data has changed
 * if the current conversation has changed or if any message has changed or if any user has changed.
 * This needs to be reduced in scope
 *
 * TODO: This needs to sort by time token; object keys are not guarenteed to retain order in js.
 */
export const getCurrentConversationMessages = createSelector(
  [getMessagesById, getCurrentConversationId, getUsersById],
  (messages, conversationId, users) => {
    return typeof messages !== 'undefined' ? messages[conversationId]
      ? Object.values(messages[conversationId])
          .filter((message) => message.channel === conversationId)
          // .map((message) => {
          //   // if the user is unknown queue up a request for the missing data
          //   return {
          //     ...message,
          //     timetoken: String(message.timetoken),
          //     sender: users[message.message.senderId]
          //       ? {
          //           id: message.message.senderId,
          //           name: message.message.senderId,
          //         }
          //       : {
          //           id: "unknown",
          //           name: "unknown",
          //         },
          //   };
          // })
          .sort((messageA, messageB) => {
            if (messageA.timetoken === messageB.timetoken) {
              return 0;
            } else if (messageA.timetoken > messageB.timetoken) {
              return 1;
            } else {
              return -1;
            }
          })
      : [] 
      : [];
  }
);

// This returns the messages by conversationId in descending order 
// with repesct to their timetoken
export const getMostRecentMessage = (conversationId) => createSelector(
  [getMessagesById],
  (messages) => {
    return typeof messages !== 'undefined' 
      ? messages[conversationId]
        ? Object.values(messages[conversationId])
          .filter((message) => message.channel === conversationId)
          .sort((messageA, messageB) => {
            if (messageA.timetoken === messageB.timetoken) {
              return 0;
            } else if (messageA.timetoken > messageB.timetoken) {
              return -1;
            } else {
              return 1;
            }
          })
        : [] 
      : [];
  }
);
