import firebase, { auth } from "../../services/firebase";
import { v4 as uuidv4 } from "uuid";
import { BackendEndpoint } from "../../constants";
import {getAuth, onAuthStateChanged,signInWithEmailAndPassword,signInWithPopup, GoogleAuthProvider, createUserWithEmailAndPassword} from "firebase/auth";

const tokenRefreshTimer = null;
const fourMinutesThirtySeconds = 270000; // In Miliseconds

// TODO: Really should be using axios and Refresh Token + Access Token model, rather than just Access Token
function refreshTheToken() {
  clearTimeout(tokenRefreshTimer);
  fetch(`${BackendEndpoint}api/v1/refresh/1`, {
    method: "POST",
    credentials: "include",
  })
    .then(() => {
      setTimeout(() => refreshTheToken(), fourMinutesThirtySeconds);
    })
    .catch((error) => {
      console.error("Could not refresh token.");
      console.error(error);
    });
}

export function setTokenRefreshTimer() {
  setTimeout(() => refreshTheToken(), fourMinutesThirtySeconds);
}

export function unsetTokenRefreshTimer() {
  clearTimeout(tokenRefreshTimer);
}

export async function signup(
  email,
  password,
  firstName,
  lastName,
  userName,
  type,
  institution,
  program
) {
  await createUserWithEmailAndPassword(auth, email, password)
  .then((userCredential => {
    const user = userCredential.user;
    saveUser(
      email,
      firstName,
      lastName,
      userName,
      type,
      institution,
      program
    );
  }))
  .catch((error) => {
    
    throw error;
  })
}

export function signin(email, password) {
  const auth = getAuth();
  return signInWithEmailAndPassword(auth, email, password);
}

export async function signInWithGoogle() {
  const provider = new GoogleAuthProvider();
  await signInWithPopup(auth, provider);
  var user = auth.currentUser;
  return user;
}

export async function getUserProfile() {
  var user = auth.currentUser;
  const response = await fetch(`${BackendEndpoint}api/v1/users`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
  })
    .then((response) => response.json())
    .then((responseData) => {
      let data = responseData.data;
      let userProfile = data.filter(
        (knownUser) => knownUser.email === user.email
      );
      return userProfile;
    })
    .catch((error) => {
      
    });
  return response && response.length != 0 ? response[0] : {};
}

export async function saveUser(
  email,
  firstName,
  lastName,
  userName,
  type,
  institution,
  program
) {
  const auth = getAuth();
  auth.onAuthStateChanged(async user => { 
    if(user){
      var uuid = user.uid;
      var user = auth.currentUser;
      const response = await fetch(`${BackendEndpoint}api/v1/users`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          firstName: firstName,
          lastName: lastName,
          userName: userName,
          email: email,
          institution: institution,
          program: program,
          uuid: user.uid,
          photoURL: "https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png"
        }),
        credentials: "include",
      }).catch((error) => {
        
        throw error;
      });

      const institutionId = await fetch(`${BackendEndpoint}api/v1/institution`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          name: institution,
          isHighSchool: false
        }),
        credentials: "include",
      })
      .then((res) => res.json())
      .then(
        (result) => {
          if(result.data !== null){
            return result.data.Institutionid
          }
        })
      .catch((error) => {
        
        throw error;
      });

      const addenrollment = await fetch(`${BackendEndpoint}api/v1/enrollment/users/${uuid}`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          institution: parseInt(institutionId),
          program: program,
          type: type
        }),
        credentials: "include",
      }).catch((error) => {
        
        throw error;
      });


      if (response.status === 400) {
        throw Error("Username already taken. Please try another username.");
      }
    }
  });
  
}


export async function updateUser(
  firstName,
  lastName,
  institution,
  program,
  type
) {
  const auth = getAuth();
  auth.onAuthStateChanged(async user => { 
    if(user){
      var user = auth.currentUser;
      var uuid = user.uid;
      const response = await fetch(`${BackendEndpoint}api/v1/users/${uuid}`, {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          firstName: firstName,
          lastName: lastName,
        }),
        credentials: "include",
      }).catch((error) => {
        
        throw error;
      });

      const institutionId = await fetch(`${BackendEndpoint}api/v1/institution`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          name: institution,
          isHighSchool: false
        }),
        credentials: "include",
      })
      .then((res) => res.json())
      .then(
        (result) => {
          if(result.data !== null){
            return result.data.Institutionid
          }
        })
      .catch((error) => {
        
        throw error;
      });

      const addenrollment = await fetch(`${BackendEndpoint}api/v1/enrollment/users/${uuid}`, {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          institution: parseInt(institutionId),
          program: program,
          type: type
        }),
        credentials: "include",
      }).catch((error) => {
        
        throw error;
      });

      if (response.status === 400) {
        throw Error("Username already taken. Please try another username.");
      }
    }
  });
  
}
export function logout() {
  return auth.signOut();
}
