import { HourglassBottom } from "@mui/icons-material";
import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import ReactMde, { ReactMdeProps } from "react-mde";
import styled from "styled-components";

function generateMarkdownPreview(markdown: string) {
  return Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>);
}

const ReactionEditorContainer = styled.div`
  margin-bottom: 1rem;
`;

interface ReactionEditorProps {
  childProps: ReactMdeProps["childProps"];
  onChange: ReactMdeProps["onChange"];
  text: string;
}

export const ReactionEditor = ({
  childProps,
  onChange,
  text = "",
}: ReactionEditorProps) => {
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");

  return (
    <ReactionEditorContainer>
      <ReactMde
        value={text}
        onChange={onChange}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        generateMarkdownPreview={generateMarkdownPreview}
        childProps={childProps}
      />
    </ReactionEditorContainer>
  );
};
