import React, { FC } from "react";
import { ForumLayout } from "../ForumLayout";
import "react-mde/lib/styles/css/react-mde-all.css";
import { NewPostContent } from "./NewPostContent";
import { NewPostControls } from "./NewPostControls";
import { useParams } from "react-router";

export const NewPostPage: FC = ({}) => {
  const { id } = useParams<{ id: string | undefined }>();
  return (
    <ForumLayout
      contentComponent={<NewPostContent />}
      controlsComponent={<NewPostControls />}
    />
  );
};
