import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../config/store";
import { Comment } from "../reactionTypes";

export type CommentSortCriterion = "top" | "new";

interface PostPageState {
  commentsByParentId: {
    [id: number]: Comment[];
  };
  sortCriterion: CommentSortCriterion;
  commentToUserMap: { [commentRxnId: number]: any };
}

const initialState: PostPageState = {
  commentsByParentId: {},
  sortCriterion: "new",
  commentToUserMap: {},
};

const postPageSlice = createSlice({
  name: "postPage",
  initialState,
  reducers: {
    // IMPORTANT ASSUMPTION: Comments are grouped by parentId, IN ORDER AS GIVEN IN THE ACTION
    setComments(state, action: PayloadAction<Comment[]>) {
      const newCommentsByParentId: PostPageState["commentsByParentId"] = {};

      for (const comment of action.payload) {
        // Initialize the child array if it doesn't exist
        if (!(comment.parentUid in newCommentsByParentId)) {
          newCommentsByParentId[comment.parentUid] = [];
        }
        newCommentsByParentId[comment.parentUid].push(comment);
      }

      state.commentsByParentId = newCommentsByParentId;
    },
    changeCommentSortCriteria(
      state,
      action: PayloadAction<CommentSortCriterion>
    ) {
      state.sortCriterion = action.payload;
    },
    updateCommentToUserMap(
      state,
      action: PayloadAction<{ reactionid: number, user: any }>
    ) {
      state.commentToUserMap = { ...state.commentToUserMap, [action.payload.reactionid]: action.payload.user }
    }
  },
});

// NOTE: These should not be called when the user is not logged in; otherwise the root state is `undefined`
export function getPostPageSlice(state: RootState): PostPageState {
  return state!.postPage;
}

export const getCommentsByParentId = createSelector(
  [getPostPageSlice],
  (postPageSlice) => postPageSlice.commentsByParentId
);

export const getCommentSortCriteria = createSelector(
  [getPostPageSlice],
  (postPageSlice) => postPageSlice.sortCriterion
);

export const getCommentToUserMap = createSelector(
  [getPostPageSlice],
  (postPageSlice) => postPageSlice.commentToUserMap
);


export const { changeCommentSortCriteria, setComments, updateCommentToUserMap } = postPageSlice.actions;

export default postPageSlice.reducer;
