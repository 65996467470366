import React, { FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { auth } from "../services/firebase";

const SiteTitle = styled(Link)`
  /* font-size: var(--font-size-xl); */
`;

const NavBar = styled.nav`
  background-color: #011d84;
  /* background-image: linear-gradient(to right, #011d84, #011d84, #415dc4); */
`;

const HeaderControls = styled.div`
  /* display: flex;
  flex-direction: row;
  align-content: center; */
`;

export const Header: FC = () => {
  return (
    <header>
      <NavBar className="navbar navbar-expand-sm navbar-dark justify-content-between">
        <SiteTitle className="navbar-brand mr-4" to="/">
          uConverse
        </SiteTitle>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className="collapse navbar-collapse justify-content-between"
          id="navbarNavAltMarkup"
        >
          <HeaderControls className="navbar-nav">
            <Link className="app-nav-link" to="/search">
              Find Mentors
            </Link>
            <Link className="app-nav-link" to="/mentorship">
              Forum
            </Link>
            <Link className="app-nav-link" to="/chat">
              Chat
            </Link>
            <Link className="app-nav-link" to="/infohub">
              Info Hub
            </Link>
          </HeaderControls>
          <div>
            {auth.currentUser ? (
              <>
                <Link className="app-nav-link" to="/profile">
                  Profile
                </Link>
                <a
                  className="app-nav-link"
                  href=""
                  onClick={() => auth.signOut()}
                >
                  Sign Out
                </a>
              </>
            ) : (
              <>
                <Link className="app-nav-link" to="/login">
                  Sign In
                </Link>
                <Link className="app-nav-link" to="/signup">
                  Sign Up
                </Link>
              </>
            )}
          </div>
        </div>
      </NavBar>
    </header>
  );
};
