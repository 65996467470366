import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../config/store";
import { Topic } from "../Topic";

export type SortCriteria = "top" | "new";

interface PostListPageState {
  selectedTopics: Topic[];
  sortCriteria: SortCriteria;
}

const initialState: PostListPageState = {
  selectedTopics: [],
  sortCriteria: "new",
};

const postListPageSlice = createSlice({
  name: "postListPage",
  initialState,
  reducers: {
    addSelectedTopic(state, action: PayloadAction<Topic>) {
      state.selectedTopics.push(action.payload);
    },
    removeSelectedTopicAtIndex(state, action: PayloadAction<number>) {
      const indexToRemoveFrom = action.payload;
      state.selectedTopics.splice(indexToRemoveFrom, 1); // Remove 1 element from indexToRemoveFrom
    },
    changeSortCriteria(state, action: PayloadAction<SortCriteria>) {
      state.sortCriteria = action.payload;
    },
  },
});

// NOTE: These should not be called when the user is not logged in; otherwise the root state is `undefined`
export function getPostListPageSlice(state: RootState): PostListPageState {
  return state!.postList;
}

export const getSelectedTopics = createSelector(
  [getPostListPageSlice],
  (postListPageSlice) => postListPageSlice.selectedTopics
);

export const getSelectedTopicIds = createSelector(
  [getPostListPageSlice],
  (postListPageSlice) =>
    new Set(postListPageSlice.selectedTopics.map((topic) => topic.Topicid))
);

export const getSortCriteria = createSelector(
  [getPostListPageSlice],
  (postListPageSlice) => postListPageSlice.sortCriteria
);

export const {
  addSelectedTopic,
  removeSelectedTopicAtIndex,
  changeSortCriteria,
} = postListPageSlice.actions;

export default postListPageSlice.reducer;
