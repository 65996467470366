import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { signup, signInWithGoogle, getUserProfile } from "./auth";
import { universityList, programList } from "../../constants";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

class SignUp extends Component {
  constructor() {
    super();
    this.state = {
      error: null,
      email: "",
      password: "",
      institution: "",
      program: "",
      type: "",
      firstName: "",
      lastName: "",
      userName: "",
      displayType: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.googleSignUp = this.googleSignUp.bind(this);
  }

  handleChange(event) {
    if(event.target.value == "undergrad / grad student"){
      this.setState({
        type: "mentor",
        displayType: event.target.value,
      });  
    }else if(event.target.value == "high school student"){
      this.setState({
        type: "student",
        displayType: event.target.value,
      });  
    }else{
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  }

  handleAutocompleteChange(target, value) {
    
    this.setState({
      [target]: value,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ error: "" });
    try {
      await signup(
        this.state.email,
        this.state.password,
        this.state.firstName,
        this.state.lastName,
        this.state.userName,
        this.state.type,
        this.state.institution,
        this.state.program
      );
      let userProfile = await getUserProfile();
      const { history } = this.props;
      if(history){
        history.push({pathname: '/search', userProfile: userProfile})
      }  
    } catch (error) {
      this.setState({ error: error.message });
    }
  }

  async googleSignUp() {
    var user = await signInWithGoogle();
    const { history } = this.props;
    if(history){
      history.push({pathname: '/profile', user: user})
    }
  }

  render() {
    return (
      <div className="container">
        <form className="mt-5 py-5 px-5" onSubmit={this.handleSubmit}>
          <h1>
            Sign Up to
            <Link className="title ml-2" to="/">
              uConverse
            </Link>
          </h1>
          <p className="lead">Fill in the form below to create an account.</p>
          <div className="form-group">
            <input
              className="form-control"
              placeholder="Username"
              name="userName"
              type="text"
              onChange={this.handleChange}
              value={this.state.userName}
              required
            ></input>
          </div>
          <div className="form-group">
            <input
              className="form-control"
              placeholder="Email"
              name="email"
              type="email"
              onChange={this.handleChange}
              value={this.state.email}
              required
            ></input>
          </div>
          <div className="form-group">
            <input
              className="form-control"
              placeholder="Password"
              name="password"
              onChange={this.handleChange}
              value={this.state.password}
              type="password"
              required
            ></input>
          </div>
          <div className="form-group">
            <input
              className="form-control"
              placeholder="First Name"
              name="firstName"
              type="text"
              onChange={this.handleChange}
              value={this.state.firstName}
              required
            ></input>
          </div>
          <div className="form-group">
            <input
              className="form-control"
              placeholder="Last Name"
              name="lastName"
              type="text"
              onChange={this.handleChange}
              value={this.state.lastName}
              required
            ></input>
          </div>
          <Autocomplete
            id="institution"
            className="form-group"
            style={{ width: "600px" }}
            freeSolo
            options={universityList.map((option) => option)}
            value={this.state.institution}
            onChange={(event, value) => {this.handleAutocompleteChange("institution", value)}}
            renderInput={(params) => <TextField {...params} name="institution" label="University / School Name" onChange={this.handleChange}
             required  />}
          />
          <Autocomplete
            id="program"
            className="form-group"
            style={{ width: "600px" }}
            freeSolo
            options={programList.map((option) => option)}
            value={this.state.program}
            name="program"
            onChange={(event, value) => {this.handleAutocompleteChange("program", value)}}
            renderInput={(params) => <TextField {...params} name="program" label="Program / Grade" onChange={this.handleChange}
             required  />}
          />
          <div>
            <label>
              What is your role? &nbsp;&nbsp;
              <select
                name="type"
                value={this.state.displayType}
                onChange={this.handleChange}
                required
              >
                <option option=""></option>
                <option option="student">high school student</option>
                <option option="mentor">undergrad / grad student</option>
              </select>
            </label>
          </div>
          <p>By signing up, you agree to our <a href="privacypolicy.html">privacy policy</a>.</p>
          <div className="form-group">
            {this.state.error ? (
              <p className="text-danger">{this.state.error}</p>
            ) : null}
            <button className="btn btn-primary px-5" type="submit">
              Sign up
            </button>
          </div>
          <p>You can also sign up with Google</p>
          <button
            className="btn btn-danger mr-2"
            type="button"
            onClick={this.googleSignUp}
          >
            Sign up with Google
          </button>
          <hr></hr>
          <p>
            Already have an account? <Link to="/login">Login</Link>
          </p>
        </form>
      </div>
    );
  }
}

export default withRouter(SignUp)