import React, { FC } from "react";
import { Button as BootstrapButton } from "react-bootstrap";
import styled from "styled-components";
import { Ratings } from "../components/Ratings";
import { Post } from "../reactionTypes";
import { formatDistance } from "date-fns";
import { useHistory } from "react-router-dom";

interface SuggestedPostProps {
  post: Post;
}

const SuggestedPostContainer = styled.div`
  /* color: #747474; */
  :hover {
    cursor: pointer;
    .underlinable {
      text-decoration: underline;
    }
  }
`;

const PostDateLink = styled.div`
  color: #9ea3a9;
  /* :hover {
    color: gray;
    text-decoration: underline;
  } */
`;

const PostAdditionalDetails = styled.div`
  color: #747474;
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const SuggestedPost = ({ post }: SuggestedPostProps) => {
  const postdateDistance = formatDistance(
    new Date(post.reaction.postdate),
    Date.now(),
    {
      addSuffix: true,
    }
  );

  const history = useHistory();

  return (
    <SuggestedPostContainer
      onClick={() => {
        history.push(`${post.reaction.reactionid}`);
        history.go(0); // TODO: remove - hack because the contents of the page didn't change
      }}
    >
      <div className="underlinable">{post.title}</div>
      <PostAdditionalDetails>
        <Ratings
          upvotes={post.reaction.upvotes}
          downvotes={post.reaction.downvotes}
        />
        <PostDateLink>{postdateDistance}</PostDateLink>
      </PostAdditionalDetails>
    </SuggestedPostContainer>
  );
};

const Button = styled(BootstrapButton)`
  margin-top: 0;
  margin-bottom: 1.25rem;
`;

const SuggestedPostsTitle = styled.div`
  margin: 1.5rem 0;
`;

const SuggestedPostsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

interface PostControlsProps {
  suggestedPosts: Post[];
  post: Post;
}

export const PostControls = ({ suggestedPosts, post }: PostControlsProps) => {
  return (
    <>
      <Button
        onClick={() => {
          // Hack for now
          document.getElementById("root-comment-list")?.scrollIntoView(true);
        }}
      >
        Go To Comments
      </Button>
      <hr style={{ margin: 0 }} />
      <SuggestedPostsTitle>Suggested Posts:</SuggestedPostsTitle>
      <SuggestedPostsList>
        {suggestedPosts
          .filter(
            // Remove the current post if it is in the list of suggestions
            (suggestion) =>
              suggestion.reaction.reactionid !== post.reaction.reactionid
          )
          .slice(0, 3) // Take only the top 3
          .map((post) => (
            <SuggestedPost post={post} />
          ))}
      </SuggestedPostsList>
    </>
  );
};
