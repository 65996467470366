import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import {saveUser, updateUser, signup, signInWithGoogle } from "./auth";
import {getAuth, deleteUser} from "firebase/auth";
import { BackendEndpoint, universityList, programList } from "../../constants";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

class Profile extends Component {
  constructor() {
    super();
    this.state = {
      error: null,
      email: "",
      password: "",
      institution: "",
      program: "",
      type: "",
      firstName: "",
      lastName: "",
      userName: "",
      user: null,
      greeting: "",
      authenticated: false,
      returnUser: false,
      institutionid: 0,
      displayType: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({user: this.props.location.user})
    this.setState({email: this.props.location.user ? this.props.location.user.email : ''})
    this.setState({greeting: this.props.location.user ? 'Hi '+ this.props.location.user.displayName + '! ': ''})
    const auth = getAuth();
    auth.onAuthStateChanged(async user => { 
      if(user){
        this.setState({authenticated: true})
        this.setState({email: user.email})
        
        var user = auth.currentUser;
        var uuid = user.uid;
        const response = await fetch(`${BackendEndpoint}api/v1/users/${uuid}`, {
          credentials: "include",
        })
        .then((res) => res.json())
        .then(
          async (result) => {
            
            var data = result.data
            this.setState({userName: data.Username, firstName: data.FirstName, lastName: data.LastName})
            const enrollmentResponse = await fetch(`${BackendEndpoint}api/v1/enrollment/users/${uuid}`, {
              credentials: "include",
            })
            .then((res) => res.json())
            .then(
              (result) => {
                var data = result.data
                if(data !== null){
                  this.setState({returnUser: true})
                  data = data[0]
                  
                  this.setState({institution: data.Name})
                  this.setState({institutionid: data.Institutionid})
                  var displayType = ""
                  var type = ""
                  if(data.IsMentor.Bool){
                    displayType = "undergrad / grad student"
                    type = "mentor"
                  }else{
                    displayType = "high school student"
                    type = "student"
                  }
                  this.setState({displayType: displayType, type: type})
                  if(data.Program !== null){
                    this.setState({program: data.Program.String, type: "Hi"})
                  }
                }
              }
              ,(error) => {
                
                throw error;
              });    
          }
          ,(error) => {
            
            throw error;
          });
      }
    });
  }

  handleChange(event) {
    if(event.target.value == "undergrad / grad student"){
      this.setState({
        type: "mentor",
        displayType: event.target.value,
      });  
    }else if(event.target.value == "high school student"){
      this.setState({
        type: "student",
        displayType: event.target.value,
      });  
    }else{
      
      
      this.setState({
        [event.target.name]: event.target.value,
      });
  
    }
  }

  handleAutocompleteChange(target, value) {
    this.setState({
      [target]: value,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ error: "" });
    if(this.state.returnUser){
      try {
        await updateUser(
          this.state.firstName,
          this.state.lastName,
          this.state.institution,
          this.state.program,
          this.state.type,
        );
      } catch (error) {
        this.setState({ error: error.message });
      }
    }
    else{
      try {
        await saveUser(
          this.state.email,
          this.state.firstName,
          this.state.lastName,
          this.state.userName,
          this.state.type,
          this.state.institution,
          this.state.program
        );
      } catch (error) {
        this.setState({ error: error.message });
      }
    }
    
    const { history } = this.props;
    if(history){
      history.push({pathname: '/search', user: this.state.user})
    }
  }
  
  render() {
    return (
      <div className="container">
        <form className="mt-5 py-5 px-5" onSubmit={this.handleSubmit}>
          <h1>
            {this.state.greeting}
            Your
            <Link className="title ml-2" to="/">
              uConverse
            </Link>
            &nbsp;Profile
          </h1>
          <p className="lead">Add additional details about yourself.</p>
          <div className="form-group">
            <label>Username</label>
            <input
              className="form-control"
              placeholder="Username"
              name="userName"
              type="text"
              onChange={this.handleChange}
              value={this.state.userName}
              readOnly={this.state.returnUser}
              required={this.state.authenticated}
            ></input>
          </div>
          <div className="form-group">
            <label>First Name</label>
            <input
              className="form-control"
              placeholder="Type your first name here"
              name="firstName"
              type="text"
              onChange={this.handleChange}
              value={this.state.firstName}
              required
            ></input>
          </div>
          <div className="form-group">
          <label>Last Name</label>
            <input
              className="form-control"
              placeholder="Type your last name here"
              name="lastName"
              type="text"
              onChange={this.handleChange}
              value={this.state.lastName}
              required
            ></input>
          </div>
          <Autocomplete
            id="institution"
            className="form-group"
            style={{ width: "600px" }}
            freeSolo
            options={universityList.map((option) => option)}
            value={this.state.institution}
            onChange={(event, value) => {this.handleAutocompleteChange("institution", value)}}
            renderInput={(params) => <TextField {...params} name="institution" label="University / School Name" onChange={this.handleChange}
             required  />}
          />
          <Autocomplete
            id="program"
            className="form-group"
            style={{ width: "600px" }}
            freeSolo
            options={programList.map((option) => option)}
            value={this.state.program}
            name="program"
            onChange={(event, value) => {this.handleAutocompleteChange("program", value)}}
            renderInput={(params) => <TextField {...params} name="program" label="Program / Grade" onChange={this.handleChange}
             required  />}
          />
          <div>
            <label>
              What is your role? &nbsp;&nbsp;
              <select
                name="type"
                value={this.state.displayType}
                onChange={this.handleChange}
                required
                disabled={true}
              >
                <option option=""></option>
                <option option="student">high school student</option>
                <option option="mentor">undergrad / grad student</option>
              </select>
            </label>
          </div>
          <div className="form-group">
            {this.state.error ? (
              <p className="text-danger">{this.state.error}</p>
            ) : null}
            <button className="btn btn-primary px-5" type="submit">
              Update Profile
            </button>
          </div>
          <hr></hr>
        </form>
        <div className="mt-1 py-2 px-5">
        <button class="btn btn-danger px-5" type="submit" onClick={()=>{
          if(window.confirm("Are you sure you want to delete the account?")){
            const auth = getAuth();
            const user = auth.currentUser;
            const uuid = user.uid
            if(user){
              fetch(`${BackendEndpoint}api/v1/account/users/${uuid}`, {
                method: 'DELETE',
                credentials: "include",
              })
              .then((res)=> {
                deleteUser(user).then(() => {
                  alert("Signing you out and deleting your account. Goodbye.");
                }).catch((error) => {
                  alert("Sorry, there was a problem. Could not delete your account.");
                });  
              },(error) => {
                alert("Sorry, there was a problem. Could not delete your account.");
              })
            }
            // auth.signOut();
          }
        }}>
            Delete Account
        </button>
        </div>
      </div>
    );
  }
}


export default withRouter(Profile)