import React, { Component } from "react";
import Footer from "../components/Footer";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import { Jumbotron } from "react-bootstrap";
import styled from "styled-components";
import teachingPicture from "../assets/teaching.svg";
import researchingPicture from "../assets/researching.svg";
import questionsPicture from "../assets/questions.svg";
import myAnswerPicture from "../assets/myAnswer.svg";
import welcomingPicture from "../assets/welcoming.svg";

const HomeJumboTron = styled(Jumbotron)`
  background-color: #ccd9ff;
`;

const HomeJumboTron2 = styled(Jumbotron)`
  background-color: #ffccb3;
`;

const HomeJumboTron3 = styled(Jumbotron)`
  background-color: #ffccb3;
`;

const JumboTronTitle = styled.h1`
  font-size: var(--font-size-xl);
`;

const JumboTronRow = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  & > * {
    min-width: 0; // So that the children don't grow wider than the sidebar
    width: 100%;
  }
  align-items: center;
  gap: 4rem;
`;

export default class HomePage extends Component {
  render() {
    return (
      <div>
        <section>
          <HomeJumboTron fluid>
            <div className="container text-center py-5">
              <JumboTronRow>
                <img src={teachingPicture} />
                <div>
                  <JumboTronTitle>Welcome to uConverse!</JumboTronTitle>
                  <Link className="btn btn-primary px-5 mr-3" to="/search">
                    Start Chatting{" "}
                  </Link>
                  <div className="mt-4"></div>
                </div>
                <img
                  src={researchingPicture}
                  style={{ transform: "scaleX(-1)" }}
                />
              </JumboTronRow>
              {/* <Link className="btn btn-primary px-5 mr-3" to="/signup">New</Link>
                <Link className="btn px-5" to="/login">Login</Link> */}

              {/* <Spinner animation="border" role="status">
		  <span className="sr-only">Loading...</span>
		</Spinner> */}
            </div>
          </HomeJumboTron>
          <HomeJumboTron2 fluid>
            <div class="container">
              <h1 class="display-5">What is uConverse?</h1>
              <p class="lead">
                uConverse is platform to connect prospective students with
                existing students at any institution;
                <br /> we want to make it easy to receive and provide mentorship
                through 1:1 chats and public discussions.
              </p>
              <img
                src={questionsPicture}
                style={{
                  display: "block",
                  margin: "auto",
                  marginTop: "2rem",
                  width: "25%",
                }}
              />
            </div>
          </HomeJumboTron2>
          <HomeJumboTron3 fluid>
            <div
              class="container"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>
                <h1 class="display-5">How does it work?</h1>
                <ul class="lead">
                  <li>Find mentors from around the globe</li>
                  <li>Message the mentors directly</li>
                  <li>Interact with other aspiring students</li>
                  <li>Find relevant admission related posts</li>
                </ul>
              </div>
              <img src={myAnswerPicture} style={{ width: "25%" }} />
            </div>
          </HomeJumboTron3>
          <HomeJumboTron3 fluid>
            <div
              class="container"
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "1rem",
              }}
            >
              <img src={welcomingPicture} style={{ width: "25%" }} />
              <div>
                <h1 class="display-5">Why uConverse?</h1>
                <ul class="lead">
                  <li>
                    Get personalized advice from verified students who currently
                    attend your desired university
                  </li>
                  <li>
                    Discover scholarships, enrichment programs, academic
                    programs and more
                  </li>
                  <li>
                    Learn about university life, residence, academics,
                    extra-curriculars and more
                  </li>
                </ul>
              </div>
            </div>
          </HomeJumboTron3>
          <Footer></Footer>
        </section>
      </div>
    );
  }
}
