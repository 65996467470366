import { createUserDataReducer } from "pubnub-redux";
import { createSelector } from "reselect";

/**
 * Create a reducer which holds all known user objects in a normalized form
 */
export const UsersReducer = createUserDataReducer();

/**
 * Slice selector is used internally to access the state of the reducer
 */
const getUsersSlice = (state) => {
  if(typeof state !== 'undefined'){
    return state.users;
  }
}

export const getUsersById = createSelector([getUsersSlice], (users) => {
  if(typeof users !== 'undefined'){
    return users.byId;
  }
});
