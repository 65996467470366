import styled from "styled-components";

export const FullscreenCard = styled.div`
  margin: 1rem;
  padding: 1rem;

  background: #ffffff;
  border: 2px solid #cdcdcd;
  box-shadow: 0px 4px 4px rgba(141, 90, 90, 0.25);
  border-radius: 5px;

  overflow-y: auto;
`;
