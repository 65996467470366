import { createUsersListReducer } from "pubnub-redux";
import { createSelector } from "reselect";

// ========================================================
/**
 * Create a reducer which holds all known user objects in a normalized form
 */
export const UsersListReducer = createUsersListReducer();

const getUsersListSlice = (state) => state.usersList;

export const getUsersListUUIDs = createSelector(
  [getUsersListSlice],
  (usersList) => {
    return usersList.uuids;
  }
);
