import React, { FC } from "react";
import { Image } from "react-bootstrap";
import styled from "styled-components";

export const sizeToDimensionLength = {
  small: "3em",
  medium: "5em",
  large: "10em",
};

type ProfilePictureSize = keyof typeof sizeToDimensionLength;

// Include an additional prop "size" to this styled-component, for use in the styles
const RoundDiv = styled.div<{ size: ProfilePictureSize }>`
  flex-shrink: 0;
  border-style: solid;
  border-radius: 50%;
  border-width: 1px;
  border-color: grey;
  overflow: hidden;
  width: ${(props) => sizeToDimensionLength[props.size]};
  height: ${(props) => sizeToDimensionLength[props.size]};
`;

interface ProfilePictureProps {
  size: ProfilePictureSize;
  src: string;
}

export const ProfilePicture: FC<ProfilePictureProps> = ({ size, src }) => {
  return (
    <RoundDiv size={size}>
      <Image fluid src={src} />
    </RoundDiv>
  );
};
