import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import React, { FC, MouseEventHandler } from "react";
import styled from "styled-components";

export type VoteKind = "up" | "down";

// TODO: Change to Icons
function voteKindToIcon(kind: VoteKind) {
  switch (kind) {
    case "up":
      return <ThumbUpAltIcon fontSize="inherit" />;
    case "down":
      return <ThumbDownAltIcon fontSize="inherit" />;
  }
}

interface VoteCountProps {
  count: number;
  highlighted?: boolean;
  kind: VoteKind;
  onClick?: MouseEventHandler;
}

const VoteCountContainer = styled.span<
  Pick<VoteCountProps, "onClick" | "highlighted" | "kind">
>`
  white-space: nowrap;
  :hover {
    cursor: ${(props) => (props.onClick ? "pointer" : "default")};
  }
  // TODO: Update styles to work with icons
  color: ${(props) => {
    if (props.highlighted) {
      if (props.kind === "up") {
        return "limegreen";
      } else if (props.kind === "down") {
        return "red";
      }
    }
    return "default";
  }};
`;

export const VoteCount: FC<VoteCountProps> = ({
  count,
  highlighted = false,
  kind,
  onClick,
}) => {
  return (
    <VoteCountContainer onClick={onClick} highlighted={highlighted} kind={kind}>
      {count} {voteKindToIcon(kind)}
    </VoteCountContainer>
  );
};
