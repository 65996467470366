import { fetchMessageHistory, setMemberships } from "pubnub-redux";
import { focusOnConversation } from "../currentConversationModel";

/**
 * Join a conversation.
 * The membership in the conversation will be stored.
 * The channel for the converstation will be subscribed to to receive messages.
 * The new conversation will be made the selected conversation
 */
export const joinConversation = (userId, conversationId) => {
  var uuid1 = conversationId.split('&')[0];
  var uuid2 = conversationId.split('&')[1];

  if (!uuid1 || !uuid2) {
    
    return "";
  }

  var target = (userId === uuid1) ? uuid2 : uuid1;

  return (dispatch, getState, context) => {
    return dispatch(
      setMemberships({
        uuid: target,
        channels: [{ id: conversationId }],
      })
    ).then(() => dispatch(
      setMemberships({
        uuid: userId,
        channels: [{ id: conversationId }],
      })
    )).then(() => {
      context.pubnub.api.subscribe({
        channels: [conversationId],
        withPresence: true,
      });
      
      dispatch(focusOnConversation(conversationId));
      dispatch(
        fetchMessageHistory({
          channel: conversationId,
          includeMeta: true,
        })
      );
    });
  };
};
