import React, { FC, MouseEventHandler, MutableRefObject } from "react";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { GenericChip } from "./GenericChip";
import styled from "styled-components";
import { formatTopicName } from "../features/forum/Topic";

type AddTopicChipProps = {
  children?: string;
  onClick: MouseEventHandler;
};

export const AddTopicChip = React.forwardRef(
  ({ children, onClick }: AddTopicChipProps, ref) => {
    return (
      <GenericChip
        iconVariant="addable"
        ref={ref}
        onClick={onClick}
        style={{
          backgroundColor: "transparent",
          color: "grey",
          borderColor: "grey",
        }}
      >
        {children}
      </GenericChip>
    );
  }
);

type DefaultTopicChipProps = {
  children?: string;
  color: string;
};

export const DefaultTopicChip = React.forwardRef(
  ({ children, color }: DefaultTopicChipProps, ref) => {
    return (
      <GenericChip
        iconVariant="default"
        ref={ref}
        style={{
          backgroundColor: color,
          color: "white",
          border: "none",
        }}
      >
        {children && formatTopicName(children)}
      </GenericChip>
    );
  }
);

type RemovableTopicChipProps = {
  children?: string;
  color: string;
  onClick?: MouseEventHandler;
};

export const RemovableTopicChip = React.forwardRef(
  ({ children, color, onClick }: RemovableTopicChipProps, ref) => {
    return (
      <GenericChip
        iconVariant="removable"
        ref={ref}
        onClick={onClick}
        style={{
          backgroundColor: color,
          color: "white",
          border: "none",
        }}
      >
        {children && formatTopicName(children)}
      </GenericChip>
    );
  }
);

export const AddableTopicChip = React.forwardRef(
  ({ children, color, onClick }: RemovableTopicChipProps, ref) => {
    return (
      <GenericChip
        iconVariant="addable"
        ref={ref}
        onClick={onClick}
        style={{
          backgroundColor: color,
          color: "white",
          border: "none",
        }}
      >
        {children && formatTopicName(children)}
      </GenericChip>
    );
  }
);
